<template>

  <!-- mainhide -->

  <div class="hide" style="font-size: 10px; padding: 10px;">
    <!-- subhide -->
    <div class="-hide" style="color: orange;">
      <p>id: {{ this.templateData.data.id }}</p>
      <p>currency: {{ this.templateData.data.Currency }}</p>
      <p>context: {{ this.templateData.data.Context }}</p>
      <p>Memberlist: {{ this.templateData.data.Memberlist }}</p>
      <p>Employeelist: {{ employeeList }}</p>
      <p>AboList: {{ this.templateData.data.Abolist }}</p>
      <p>Customerid: {{ this.templateData.data.Customerid }}</p>
      <p>CustomerSubject: {{ this.templateData.data.CustomerSubject }}</p>
      <p>CustomerPicthumb: {{ this.templateData.data.CustomerPicthumb }}</p>
      <p>Employeeid: {{ this.templateData.data.Employeeid }}</p>
      <p>EmployeeSubject: {{ this.templateData.data.EmployeeSubject }}</p>
      <p>EmployeePicthumb: {{ this.templateData.data.EmployeePicthumb }}</p>
      <p>Aboid: {{ this.templateData.data.Aboid }}</p>
      <p>LocationId: {{ this.templateData.data.LocationId }}</p>
      <p>AboSubject: {{ this.templateData.data.AboSubject }}</p>
      <p>AboPicthumb: {{ this.templateData.data.AboPicthumb }}</p>

      &nbsp;

      <p>Contractid: {{ this.templateData.data.id }}</p>
      <p>Invoiceid: {{ this.templateData.data.Invoiceid }}</p>
      <p>InvoiceDate: {{ this.templateData.data.InvoiceDate }}</p>
      <!-- <p>RateList: {{ this.templateData.data.RateList }}</p> -->
      <p>arrRates: {{ this.templateData.data.RateList }}</p>

      &nbsp;

      <p>DateStart: {{ this.templateData.data.DateStart }}</p>
      <p>DateStop: {{ this.templateData.data.DateStop }}</p>
      <p>DateStart1: {{ this.templateData.data.DateStart1 }}</p>
      <p>DateStop1: {{ this.templateData.data.DateStop1 }}</p>
      <p>DateStart2: {{ this.templateData.data.DateStart2 }}</p>
      <p>DateStop2: {{ this.templateData.data.DateStop2 }}</p>
      <p>DateStart3: {{ this.templateData.data.DateStart3 }}</p>
      <p>DateStop3: {{ this.templateData.data.DateStop3 }}</p>
      <p>DateStart4: {{ this.templateData.data.DateStart4 }}</p>
      <p>DateStop4: {{ this.templateData.data.DateStop4 }}</p>
      <p>DateStart5: {{ this.templateData.data.DateStart5 }}</p>
      <p>DateStop5: {{ this.templateData.data.DateStop5 }}</p>
      <p>DateInvoice: {{ this.templateData.data.DateInvoice }}</p>

      &nbsp;

      <p>ExtendDay1: {{ this.templateData.data.ExtendDay1 }}</p>
      <p>ExtendDay2: {{ this.templateData.data.ExtendDay2 }}</p>
      <p>ExtendDay3: {{ this.templateData.data.ExtendDay3 }}</p>
      <p>ExtendDay4: {{ this.templateData.data.ExtendDay4 }}</p>
      <p>ExtendDay5: {{ this.templateData.data.ExtendDay5 }}</p>

      &nbsp;

      <p>ValidDates: {{ this.templateData.data.ValidDates }}</p>
      <p>CountValidDates: {{ this.templateData.data.ValidDates.length }}</p>
      <p>StartDate: {{ this.templateData.data.ValidDates.slice(0, 1) }}</p>
      <p>StopDate: {{ this.templateData.data.ValidDates.slice(-1) }}</p>

      &nbsp;

      <p>ContractPdf: {{ this.templateData.data.ContractPdf }}</p>
      <p>SignaturePng: {{ this.templateData.data.SignaturePng }}</p>
      <p>FitnessguidePdf: {{ this.templateData.data.FitnessguidePdf }}</p>
      <p>QualicertPdf: {{ this.templateData.data.QualicertPdf }}</p>
      <p>QualitopPdf: {{ this.templateData.data.QualitopPdf }}</p>
      
      &nbsp;

      <p>Price: {{ this.templateData.data.Price }}</p>
      <p>Discount: {{ this.templateData.data.Discount }}</p>
      <p>Rates: {{ this.templateData.data.Rates }}</p>
      <p>Interval: {{ this.templateData.data.Interval }}</p>
      <p>Duedays: {{ this.templateData.data.Duedays }}</p>

    </div>
    <!-- /subhide -->

    &nbsp;

    <!-- subhide -->
    <div class="-hide" style="color: green;">

    <p>id: {{ id }}</p>
    <p>memberList: {{ memberList }}</p>
    <p>employeeList: {{ employeeList }}</p>
    <p>aboList: {{ aboList }}</p>
    <p>customerId: {{ customerId }}</p>
    <p>customerSubject: {{ customerSubject }}</p>
    <p>customerPicthumb: {{ customerPicthumb }}</p>
    <p>employeeId: {{ employeeId }}</p>
    <p>employeeSubject: {{ employeeSubject }}</p>
    <p>employeePicthumb: {{ employeePicthumb }}</p>
    <p>aboId: {{ aboId }}</p>
    <p>locationId: {{ locationId }}</p>
    <p>aboSubject: {{ aboSubject }}</p>
    <p>aboPicthumb: {{ aboPicthumb }}</p>

    &nbsp;

    <p>contractId: {{ id }}</p>
    <p>invoiceId: {{ invoiceId }}</p>
    <p>invoicedate: {{ invoicedate }}</p>
    <!-- <p>RateList: {{ this.templateData.data.RateList }}</p> -->
    <p>arrRates: {{ arrRates }}</p>

    &nbsp;

    <p>dateStart: {{ dateStart }}</p>
    <p>dateStop: {{ dateStop }}</p>
    <p>dateStart1: {{ dateStart1 }}</p>
    <p>dateStop1: {{ dateStop1 }}</p>
    <p>dateStart2: {{ dateStart2 }}</p>
    <p>dateStop2: {{ dateStop2 }}</p>
    <p>dateStart3: {{ dateStart3 }}</p>
    <p>dateStop3 {{ dateStop3 }}</p>
    <p>dateStart4: {{ dateStart4 }}</p>
    <p>dateStop4: {{ dateStop4 }}</p>
    <p>dateStart5: {{ dateStart5 }}</p>
    <p>dateStop5: {{ dateStop5 }}</p>
    <p>dateInvoice: {{ dateInvoice }}</p>

    &nbsp;

    <p>dayExt1: {{ dayExt1 }}</p>
    <p>dayExt2: {{ dayExt2 }}</p>
    <p>dayExt3: {{ dayExt3 }}</p>
    <p>dayExt4: {{ dayExt4 }}</p>
    <p>dayExt5: {{ dayExt5 }}</p>

    &nbsp;

    <p>validDates: {{ validDates }}</p>
    <p>CountValidDates:</p>
    <p>StartDate:</p>
    <p>StopDate:</p>

    &nbsp;


    &nbsp;

    <p>contractpdf: {{ contractpdf }}</p>
    <p>signaturepng: {{ signaturepng }}</p>
    <p>fitnessguidepdf: {{ fitnessguidepdf }}</p>
    <p>qualicertpdf: {{ qualicertpdf }}</p>
    <p>qualitoppdf: {{ qualitoppdf }}</p>

    &nbsp;

    <p>Price: {{ price }}</p>
    <p>Discount: {{ discount }}</p>
    <p>Rates: {{ rates }}</p>
    <p>Interval: {{ interval }}</p>

    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->


  <form 
    :id="id"
    @submit="handleSubmit"
  >

  <div class="-hide">

    <DxForm 
      :id="id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >
    <DxSimpleItem 
      data-field="id"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="mastercontent"
      help-text=""
      template="mastercontent"
    >
    <DxLabel 
      :text="'mastercontent'"
      :visible="false"
      />
    </DxSimpleItem>

      <template #mastercontent>

        <div class="master-content">

          <!-- SUBMIT BUTTON  -->
          <div class="grid-x grid-margin-x align-middle title-area-content">
            <div class="auto cell">
              <h5>Vertrag bearbeiten</h5>
            </div>
            <div class="shrink cell">
              <button :id="'submitform'+id" class="cx-button"><i class="fa-light fa-file-contract"></i> {{currentUser.Translation.vueappContractFormSubmit}}</button>
            </div>
          </div>
          <!-- /SUBMIT BUTTON  -->

          <div class="grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid">

            <!-- cell one-->
            <div class="cell cell-margin-y large-3">

              <!-- dx-card 1 cell one -->
              <div class="dx-card no-paddings">

                <div class="dx-card-label grid-x align-middle hide">
                  <div class="auto cell">
                    <p>Kunde</p>
                  </div>
                  <div class="shrink cell">
                    Accordion
                  </div>
                </div>

                <!-- hide if context sap-memberactive -->
                <div class="hide" :class="{ hide: this.templateData.data.Hascustomerid }">

                <ul :id="'accordionMember'+id" class="accordion is-label" data-accordion>
                  
                  <li :id="'accItemMember'+id" class="accordion-item -hide" data-accordion-item>
                    <a href="#" class="accordion-title">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                        Kunde
                        </div>
                      </div>
                    </a>
                    <div class="accordion-content" data-tab-content>
                      <div style="height: 500px; overflow-y: scroll">

                      <DxList
                          :data-source="memberList"
                          key-expr="id"
                          :search-enabled="true"
                          :search-expr="['Subject']"
                          item-template="member-list">
                        >

                        <DxSearchEditorOptions
                          placeholder="Kunde suchen..."
                        />

                        <template #member-list="{ data }">
                          <div :id="'memberContainer'+data.id" :data-id="data.id" :data-locationid="data.LocationId" class="master-content" style="position: relative;">

                            <!-- button Detail-->
                            <div style="position: absolute; top: 0; left: 0; width: 100%; height: 48px;"
                              @click="selectmember" class="memberbutton" :id="'memberButton'+data.id">
                            </div>
                            <!-- /button Detail-->

                            <div class="grid-x align-middle ck-itemrow">
                              <div class="shrink cell">
                                <div class="user-thumb" v-if="data.Picthumb"
                                  style="margin: 0 10px 0 5px;"
                                  v-bind:style="{ 'background-image': 'url(' + data.Picthumb + ')' }" >
                                </div>
                              </div>
                              <div class="auto cell grid-x">

                                <div class="shrink cell">
                                  <div class="hide">
                                    <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                                  </div>
                                </div>

                                <div class="auto cell">
                                  <p class="ck-overflow-ellipsis strong">
                                    {{ data.Subject }}     
                                  </p>
                                  <p class="ck-overflow-ellipsis small hide">
                                    member info
                                  </p>
                                </div>

                              </div>
                            </div>

                          </div>
                        </template>

                      </DxList>

                    </div>
                    </div>
                  </li>
                </ul>


                <!-- customerIdID (hidden) -->
                <input type="text" :value="customerId" :id="'memberInput'+id" class="hide" />

                <!-- for member loop -->

                <div v-if="!customerId">
                  <div @click="openaccordion('accItemMember'+id)" class="ck-alert" style="padding: 10px;">
                    <p><i class="fa-light fa-triangle-exclamation"></i> Kunde definieren</p>
                  </div>
                </div>

              </div>
              <!-- /hide if context sap-memberactive -->


              <div class="dx-card-label grid-x align-middle hide">
                <div class="auto cell">
                  <p>Trainer</p>
                </div>
                <div class="shrink cell">
                  Accordion
                </div>
              </div>

              <ul :id="'accordionEmployee'+id" class="accordion is-label" data-accordion>
                <li :id="'accItemEmployee'+id" class="accordion-item" data-accordion-item>
                  <a href="#" class="accordion-title">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                      Trainer
                      </div>
                    </div>
                  </a>
                  <div class="accordion-content" data-tab-content>
                    <div style="height: 500px; overflow-y: scroll">

                    <DxList
                        :data-source="employeeList"
                        key-expr="id"
                        :search-enabled="true"
                        :search-expr="['Subject']"
                        item-template="employee-list">
                      >

                      <DxSearchEditorOptions
                        placeholder="Trainer suchen..."
                      />

                      <template #employee-list="{ data }">
                        <div :id="'employeeContainer'+data.id" :data-id="data.id" class="master-content" style="position: relative;">

                          <!-- button Detail-->
                          <div style="position: absolute; top: 0; left: 0; width: 100%; height: 48px;"
                            @click="selectemployee" class="employeebutton" :id="'employeeButton'+data.id">
                          </div>
                          <!-- /button Detail-->

                          <div class="grid-x align-middle ck-itemrow">
                            <div class="shrink cell">
                              <div class="user-thumb" v-if="data.Picthumb"
                                style="margin: 0 10px 0 5px;"
                                v-bind:style="{ 'background-image': 'url(' + data.Picthumb + '?v=' + ')' }" >
                              </div>
                            </div>
                            <div class="auto cell grid-x">

                              <div class="shrink cell">
                                <div class="hide">
                                  <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                                </div>
                              </div>

                              <div class="auto cell">
                                <p class="ck-overflow-ellipsis strong">
                                  {{ data.Subject }}       
                                </p>
                                <p class="ck-overflow-ellipsis small hide">
                                  employee info
                                </p>
                              </div>

                            </div>
                          </div>

                        </div>
                      </template>


                    </DxList>

                  </div>



                  </div>
                </li>
              </ul>

              <!-- employeeIdID (hidden) -->
              <input type="text" :value="employeeId" :id="'employeeInput'+id" class="hide" />

              <!-- for employee loop -->

              <div v-if="!employeeId">
                <div @click="openaccordion('accItemEmployee'+id)" class="ck-alert" style="padding: 10px;">
                  <p><i class="fa-light fa-triangle-exclamation"></i> Trainer definieren</p>
                </div>
              </div>


              <template v-for="item in employeeList" :key="item.id">
                <div v-if="item.id == employeeId" class="dx-item dx-list-item">

                  <div @click="openaccordion('accItemEmployee'+id)" class="grid-x align-middle ck-itemrow">
                    <div class="shrink cell">
                      <div class="user-thumb" v-if="item.Picthumb"
                        style="margin: 0 10px 0 5px;"
                        v-bind:style="{ 'background-image': 'url(' + item.Picthumb + '?v=' + timestamp + ')' }" >
                      </div>
                    </div>
                    <div class="auto cell grid-x">

                      <div class="shrink cell">
                        <div class="hide">
                          <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                        </div>
                      </div>

                      <div class="auto cell">
                        <p class="ck-overflow-ellipsis strong">
                          {{ item.Subject }}       
                        </p>
                        <p class="ck-overflow-ellipsis small hide">
                          employee info
                        </p>
                      </div>

                    </div>
                  </div>

                </div>
              </template>
              <!-- /for employee loop -->


              <div class="dx-card-label -has-border-top grid-x align-middle hide">
                <div class="auto cell">
                  <p>Abo</p>
                </div>
                <div class="shrink cell">
                  <p>Accordion</p>
                </div>
              </div>
              
              <ul :id="'accordionAbo'+id" class="accordion is-label" data-accordion>
                <li :id="'accItemAbo'+id" class="accordion-item" data-accordion-item>
                  <a href="#" class="accordion-title">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                      Abo
                      </div>
                    </div>
                  </a>
                  <div class="accordion-content" data-tab-content>
                    <div style="height: 500px; overflow-y: scroll">

                    <DxList
                      :data-source="aboList"
                      key-expr="id"
                      :search-enabled="true"
                      :search-expr="['Subject']"
                      item-template="abo-list">
                    >

                    <DxSearchEditorOptions
                      placeholder="Abo suchen..."
                    />

                    <template #abo-list="{ data }">

                      <div 
                        :id="'aboContainer'+data.id" 
                        :data-id="data.id" 
                        :data-price="data.Price" 
                        :data-daysvalid="data.Daysvalid" 
                        :data-priceextend="data.Priceextend" 
                        :data-abotype="data.Abotype" 
                        :data-remindertype="data.Remindertype" 
                        :data-numberentries="data.Numberentries" 
                        class="master-content" style="position: relative;"
                      >

                      <!-- button Detail-->
                      <div style="position: absolute; top: 0; left: 0; width: 100%; height: 48px;"
                        @click="selectabo" class="abobutton" :id="'aboButton'+data.id">
                      </div>
                      <!-- /button Detail-->

                        <div class="grid-x align-middle ck-itemrow">
                          <div class="shrink cell">
                            <div class="user-thumb" v-if="data.Picthumb"
                              style="margin: 0 10px 0 5px;"
                              v-bind:style="{ 'background-image': 'url(' + data.Picthumb + ')' }" >
                            </div>
                          </div>
                          <div class="auto cell grid-x">

                            <div class="shrink cell">
                              <div class="hide">
                                <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                              </div>
                            </div>

                            <div class="auto cell">
                              <p class="ck-overflow-ellipsis strong">
                                {{ data.Subject }}
                              </p>
                              <p class="ck-overflow-ellipsis small">
                                {{ this.templateData.data.Currency }} {{ formatPrice(data.Price) }} | {{ data.Daysvalid }} Tage
                              </p>
                            </div>

                          </div>
                        </div>

                      </div>
                    </template>

                  </DxList>

                  </div>

                  </div>

                </li>
              </ul>
              
              <!-- aboIdID (hidden) -->
              <input type="text" :value="aboId" :id="'aboInput'+id" class="hide" />

              <!-- for abo loop -->

              <div v-if="!aboId">
                <div @click="openaccordion('accItemAbo'+id)" class="ck-alert" style="padding: 10px;">
                  <p><i class="fa-light fa-triangle-exclamation"></i> Abo definieren</p>
                </div>
              </div>

              <template v-for="item in aboList" :key="item.id">
                <div v-if="item.id == aboId" class="dx-item dx-list-item">

                  <div @click="openaccordion('accItemAbo'+id)" class="grid-x align-middle ck-itemrow">
                    <div class="shrink cell">
                      <div class="user-thumb" v-if="item.Picthumb"
                        style="margin: 0 10px 0 5px;"
                        v-bind:style="{ 'background-image': 'url(' + item.Picthumb + '?v=' + timestamp + ')' }" >
                      </div>
                    </div>
                    <div class="auto cell grid-x">

                      <div class="shrink cell">
                        <div class="hide">
                          <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                        </div>
                      </div>

                      <div class="auto cell">
                        <p class="ck-overflow-ellipsis strong" :id="'getAboSubject' + id">
                          {{ item.Subject }}     
                        </p>
                        <p class="ck-overflow-ellipsis small">
                          {{ this.templateData.data.Currency }} {{ formatPrice(item.Price) }} | {{ item.Daysvalid }} Tage
                        </p>
                      </div>

                      <div class="shrink cell">
                        <input type="hidden" :id="'inputdaysvalid'+aboId" :value="item.Daysvalid" />
                      </div>


                    </div>
                  </div>

                </div>
              </template>
             <!-- /for abo loop -->


             <div class="dx-card-label -has-border-top grid-x align-middle hide">
                <div class="auto cell">
                  <p>Standort</p>
                </div>
                <div class="shrink cell">
                  <p>Accordion</p>
                </div>
              </div>
             

              <ul :id="'accordionLocation'+id" class="accordion is-label" data-accordion>

                <li :id="'accItemLocation'+id" class="accordion-item is-active" data-accordion-item>
                  <a href="#" class="accordion-title">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                        Standort
                      </div>
                    </div>
                  </a>
                  <div class="accordion-content is-active" data-tab-content>
                    <DxSelectBox
                      :id="'Location'+id"
                      :items="currentUser.Location"
                      :input-attr="{ 'aria-label': 'Location' }"
                      :value="locationId"
                      display-expr="locationname"
                      value-expr="locationid"
                    />
                  </div><!-- /accordion-content -->

                </li>

              </ul>
             <!-- /accordionLocation -->

             <div class="dx-card-label -has-border-top grid-x align-middle hide">
                <div class="auto cell">
                  <p>Preise</p>
                </div>
                <div class="shrink cell">
                  <p>Accordion</p>
                </div>
              </div>


              <ul :id="'accordionPrices'+id" class="accordion is-label" data-accordion>
                <li :id="'accItemPrices'+id" class="accordion-item is-active" data-accordion-item>
                  <a href="#" class="accordion-title">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                        Preise
                      </div>
                    </div>
                  </a>
                  <div class="accordion-content is-active" data-tab-content>

                    <div class="dx-card-label -has-border-top grid-x align-middle -hide">
                      <div class="auto cell">
                        <p>Nettopreis ({{ this.templateData.data.Currency }})</p>
                      </div>
                      <div class="shrink cell">
                        <p>&nbsp;</p>
                      </div>
                    </div>

                    <DxNumberBox
                      :id="'Price'+id"
                      :value="price"
                      :input-attr="{ 'aria-label': 'Price' }"
                      @value-changed="priceBoxValueChanged"

                    />


                    <div class="dx-card-label -has-border-top grid-x align-middle -hide">
                      <div class="auto cell">
                        <p>Rabatt ({{ this.templateData.data.Currency }})</p>
                      </div>
                      <div class="shrink cell">
                        <p>&nbsp;</p>
                      </div>
                    </div>


                    <DxNumberBox
                      :id="'Discount'+id"
                      :value="discount"
                      :input-attr="{ 'aria-label': 'Discount' }"
                      @value-changed="discountBoxValueChanged"
                    />


                  </div>
                  <!-- /accordion-content-->

                </li>

              </ul>
             <!-- /accordionPrices -->

             <div class="dx-card-label -has-border-top grid-x align-middle hide">
                <div class="auto cell">
                  <p>Ratenzahlung</p>
                </div>
                <div class="shrink cell">
                  <p>Accordion</p>
                </div>
              </div>

              <ul :id="'accordionRates'+id" class="accordion is-label" data-accordion>

                <li :id="'accItemRates'+id" class="accordion-item is-active" data-accordion-item>
                  <a href="#" class="accordion-title">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                        Ratenzahlung ({{ rates }} Rate<span v-if="rates > 1">n</span>)
                      </div>
                    </div>
                  </a>

                  
                  <div class="accordion-content is-active" data-tab-content>

                    <div class="dx-card-label -has-border-top grid-x align-middle -hide">
                      <div class="auto cell">
                        <p>Raten (Anzahl)</p>
                      </div>
                      <div class="shrink cell">
                        <p>&nbsp;</p>
                      </div>
                    </div>

                    <DxNumberBox
                      :id="'Rates'+id"
                      :value="rates"
                      :input-attr="{ 'aria-label': 'Rates' }"
                      @value-changed="ratesBoxValueChanged"
                    />                    
                    
                    
                    <div class="dx-card-label -has-border-top grid-x align-middle -hide">
                      <div class="auto cell">
                        <p>Interval (Monate)</p>
                      </div>
                      <div class="shrink cell">
                        <p>&nbsp;</p>
                      </div>
                    </div>

                    <DxNumberBox
                      :id="'Interval'+id"
                      :value="interval"
                      :input-attr="{ 'aria-label': 'Interval' }"
                    />

                    <div class="dx-card-label -has-border-top grid-x align-middle -hide">
                      <div class="auto cell">
                        <p>Fällig (1. Rate)</p>
                      </div>
                      <div class="shrink cell">
                        <p>&nbsp;</p>
                      </div>
                    </div>

                    <DxDateBox
                      :id="'DateInvoice'+id"
                      :value="dateInvoice"
                      :input-attr="{ 'aria-label': 'Date' }"
                      displayFormat="dd.MM.yyyy"  
                      type="date"
                    />

                    <div class="dx-card-label -has-border-top grid-x align-middle -hide">
                      <div class="auto cell">
                        <p>Zuschlag bei Ratenzahlung ({{ this.templateData.data.Currency }}/Rate)</p>
                      </div>
                      <div class="shrink cell">
                        <p>&nbsp;</p>
                      </div>
                    </div>
                    
                    <DxNumberBox
                      :id="'Priceextend'+id"
                      :value="priceextend"
                      :input-attr="{ 'aria-label': 'Priceextend' }"
                      @value-changed="priceextendBoxValueChanged"
                    />

                  </div> 
                  <!-- /accordion-content-->

                </li>

              </ul>
             <!-- /accordionRates-->


              </div>
             <!-- /dx-card 1 cell one -->

            </div>
            <!-- /cell one-->

            <!-- cell two-->
            <div class="cell cell-margin-y large-3">

              <!-- dx-card 2 cell two -->
              <div class="dx-card no-paddings">

                <ul :id="'accordionDates'+id" class="accordion is-label" data-accordion>


                  <li :id="'accItemDates'+id" class="accordion-item is-active" data-accordion-item>
                    <a href="#" class="accordion-title -has-border-top">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                          Zeitraum
                        </div>
                      </div>
                    </a>

                    <div class="accordion-content is-active" data-tab-content>

                      <div class="grid-x align-middle">
                        <div class="auto cell" style="margin-right: 5px;">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStartdate }}</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStart'+id"
                            :value="dateStart"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                            @value-changed="setdatestart"

                          />
                        </div>
                        <div class="auto cell">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStopDate }}</p>
                            </div>
                            <div v-if="this.aboId" class="shrink cell">
                              <a @click="setdatestop" :id="'setdateButton'+id" style="font-size: 18px; margin-right: 3px;">
                                <i class="fa-light fa-calendar-arrow-down"></i>
                              </a>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStop'+id"
                            :value="dateStop"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>

                      </div><!-- grid-x align-middle-->

                    </div>
                  </li>

                  <li :id="'accItemBreak'+id" class="accordion-item" data-accordion-item>
                    <a href="#" class="accordion-title -has-border-top">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                          Pausieren
                        </div>
                      </div>
                    </a>
                    <div class="accordion-content" data-tab-content>

                      <!-- rep 1-->
                      <div class="grid-x align-middle">
                        <div class="auto cell" style="margin-right: 5px;">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStartbreak }} 1</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStart1'+id"
                            :value="dateStart1"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>
                        <div class="auto cell">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStopbreak }} 1</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStop1'+id"
                            :value="dateStop1"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>

                      </div><!-- grid-x align-middle-->

                      <div class="dx-card-label grid-x align-middle">
                        <div class="auto cell">
                          <p>Unterbruch Grund 1</p>
                        </div>
                      </div>

                      <DxTextBox
                        :id="'DeskBreak1'+id"
                        :value="deskBreak1"
                        :input-attr="{ 'aria-label': 'DeskBreak1' }"
                      />
                      <!-- /rep 1-->

                      <!-- rep 2-->
                      <div :class="{ hide: !dateStart1 }">

                      <div class="grid-x align-middle">
                        <div class="auto cell" style="margin-right: 5px;">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStartbreak }} 2</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStart2'+id"
                            :value="dateStart2"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>
                        <div class="auto cell">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStopbreak }} 2</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStop2'+id"
                            :value="dateStop2"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>

                      </div><!-- grid-x align-middle-->

                      <div class="dx-card-label grid-x align-middle">
                        <div class="auto cell">
                          <p>Unterbruch Grund 2</p>
                        </div>
                      </div>

                      <DxTextBox
                        :id="'DeskBreak2'+id"
                        :value="deskBreak2"
                        :input-attr="{ 'aria-label': 'DeskBreak2' }"
                      />

                      </div><!-- /if-->
                      <!-- /rep 2-->

                      <!-- rep 3-->
                      <div :class="{ hide: !dateStart2 }">
                      <div class="grid-x align-middle">
                        <div class="auto cell" style="margin-right: 5px;">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStartbreak }} 3</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStart3'+id"
                            :value="dateStart3"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>
                        <div class="auto cell">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStopbreak }} 3</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStop3'+id"
                            :value="dateStop3"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>

                      </div><!-- grid-x align-middle-->

                      <div class="dx-card-label grid-x align-middle">
                        <div class="auto cell">
                          <p>Unterbruch Grund 3</p>
                        </div>
                      </div>

                      <DxTextBox
                        :id="'DeskBreak3'+id"
                        :value="deskBreak3"
                        :input-attr="{ 'aria-label': 'DeskBreak3' }"
                      />
                      </div><!-- /if-->
                      <!-- /rep 3-->

                      <!-- rep 4-->
                      <div :class="{ hide: !dateStart3 }">
                      <div class="grid-x align-middle">
                        <div class="auto cell" style="margin-right: 5px;">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStartbreak }} 4</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStart4'+id"
                            :value="dateStart4"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>
                        <div class="auto cell">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStopbreak }} 4</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStop4'+id"
                            :value="dateStop4"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>

                      </div><!-- grid-x align-middle-->

                      <div class="dx-card-label grid-x align-middle">
                        <div class="auto cell">
                          <p>Unterbruch Grund 4</p>
                        </div>
                      </div>

                      <DxTextBox
                        :id="'DeskBreak4'+id"
                        :value="deskBreak4"
                        :input-attr="{ 'aria-label': 'DeskBreak4' }"
                      />
                      </div><!-- /if-->
                      <!-- /rep 4-->

                      <!-- rep 5-->
                      <div :class="{ hide: !dateStart4 }">
                      <div class="grid-x align-middle">
                        <div class="auto cell" style="margin-right: 5px;">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStartbreak }} 5</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStart5'+id"
                            :value="dateStart5"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>
                        <div class="auto cell">
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ currentUser.Translation.vueappGlobalStopbreak }} 5</p>
                            </div>
                          </div>
                          <DxDateBox
                            :id="'DateStop5'+id"
                            :value="dateStop5"
                            :input-attr="{ 'aria-label': 'Date' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                          />
                        </div>

                      </div><!-- grid-x align-middle-->

                      <div class="dx-card-label grid-x align-middle">
                        <div class="auto cell">
                          <p>Unterbruch Grund 5</p>
                        </div>
                      </div>

                      <DxTextBox
                        :id="'DeskBreak5'+id"
                        :value="deskBreak5"
                        :input-attr="{ 'aria-label': 'DeskBreak5' }"
                      />
                      </div><!-- /if-->
                      <!-- /rep 5-->

                    </div><!-- accordion-content-->

                   </li>


                   <li :id="'accItemExtend'+id" class="accordion-item" data-accordion-item>
                    <a href="#" class="accordion-title -has-border-top">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                          Verlängern
                        </div>
                      </div>
                    </a>
                    <div class="accordion-content" data-tab-content>

                      <!-- rep 1-->
                      <div class="grid-x">
                        <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                          
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Tage</p>
                            </div>
                          </div>

                          <DxNumberBox
                            :id="'ExtendDay1'+id"
                            :value="dayExt1"
                            :input-attr="{ 'aria-label': 'ExtendDay1' }"
                          />

                        </div>

                        <div class="auto cell">

                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Verlängern Grund 1</p>
                            </div>
                          </div>

                          <DxTextBox
                            :id="'ExtendText1'+id"
                            :value="textExt1"
                            :input-attr="{ 'aria-label': 'ExtendText1' }"
                          />
                        </div>

                      </div><!-- grid-x-->

                      <!-- /rep 1-->

                      <!-- rep 2-->
                      <div :class="{ hide: !dayExt1 }">

                      <div class="grid-x">
                        <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                          
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Tage</p>
                            </div>
                          </div>

                          <DxNumberBox
                            :id="'ExtendDay2'+id"
                            :value="dayExt2"
                            :input-attr="{ 'aria-label': 'ExtendDay2' }"
                          />

                        </div>

                        <div class="auto cell">

                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Verlängern Grund 2</p>
                            </div>
                          </div>

                          <DxTextBox
                            :id="'ExtendText2'+id"
                            :value="textExt2"
                            :input-attr="{ 'aria-label': 'ExtendText2' }"
                          />
                        </div>

                      </div><!-- grid-x-->

                      </div><!-- /if-->
                      <!-- /rep 2-->

                      <!-- rep 3-->
                      <div :class="{ hide: !dayExt2 }">

                      <div class="grid-x">
                        <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                          
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Tage</p>
                            </div>
                          </div>

                          <DxNumberBox
                            :id="'ExtendDay3'+id"
                            :value="dayExt3"
                            :input-attr="{ 'aria-label': 'ExtendDay3' }"
                          />

                        </div>

                        <div class="auto cell">

                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Verlängern Grund 3</p>
                            </div>
                          </div>

                          <DxTextBox
                            :id="'ExtendText3'+id"
                            :value="textExt3"
                            :input-attr="{ 'aria-label': 'ExtendText3' }"
                          />
                        </div>

                      </div><!-- grid-x-->

                      </div><!-- /if-->
                      <!-- /rep 3-->

                      <!-- rep 4-->
                      <div :class="{ hide: !dayExt3 }">

                      <div class="grid-x">
                        <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                          
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Tage</p>
                            </div>
                          </div>

                          <DxNumberBox
                            :id="'ExtendDay4'+id"
                            :value="dayExt4"
                            :input-attr="{ 'aria-label': 'ExtendDay4' }"
                          />

                        </div>

                        <div class="auto cell">

                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Verlängern Grund 4</p>
                            </div>
                          </div>

                          <DxTextBox
                            :id="'ExtendText4'+id"
                            :value="textExt4"
                            :input-attr="{ 'aria-label': 'ExtendText4' }"
                          />
                        </div>

                      </div><!-- grid-x-->

                      </div><!-- /if-->
                      <!-- /rep 4-->

                      <!-- rep 5-->
                      <div :class="{ hide: !dayExt4 }">

                      <div class="grid-x">
                        <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                          
                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Tage</p>
                            </div>
                          </div>

                          <DxNumberBox
                            :id="'ExtendDay5'+id"
                            :value="dayExt5"
                            :input-attr="{ 'aria-label': 'ExtendDay5' }"
                          />

                        </div>

                        <div class="auto cell">

                          <div class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>Verlängern Grund 5</p>
                            </div>
                          </div>

                          <DxTextBox
                            :id="'ExtendText5'+id"
                            :value="textExt5"
                            :input-attr="{ 'aria-label': 'ExtendText5' }"
                          />
                        </div>

                      </div><!-- grid-x-->
                      
                      </div><!-- /if-->
                      <!-- /rep 5-->

                    </div><!-- accordion-content-->
                  </li>

                  <li :id="'accItemCalendar'+id" class="accordion-item is-active" data-accordion-item>
                    <a href="#" class="accordion-title">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                          Kalender
                        </div>
                      </div>
                    </a>
                    <div class="accordion-content is-active" data-tab-content>

                      <div class="ck-calendar-wrap show-disabled">
                        <DxCalendar
                          :id="'DxCalendar'+id"
                          :value="initialValue"
                          :show-week-numbers="false"
                          :first-day-of-week="1"
                          :selection-mode="selectionMode"
                          :min="minDateValue"
                          :max="maxDateValue"
                          :disabled-dates="disabledDates"
                        />
                      </div>

                    </div><!-- accordion-content-->
                  </li>

                </ul>

              </div><!-- /dx-card 2 cell two-->

              <!-- dx-card 3 cell two -->
              <div class="dx-card no-paddings">

                <ul :id="'accordionType'+id" class="accordion is-label" data-accordion>

                  <li class="accordion-item is-active" data-accordion-item>
                    <a href="#" class="accordion-title -has-border-top">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                          Typ Abo
                        </div>
                      </div>
                    </a>
                    <div class="accordion-content is-active" data-tab-content>
                      <DxSelectBox
                      :id="'abotype'+this.templateData.data.id"
                      :items="optionabotypelist"
                      :input-attr="{ 'aria-label': 'Abotype' }"
                      :value="this.abotype"
                      display-expr="typename"
                      value-expr="typeid"
                      />
                      <!-- /Abotype-->


                      <!-- Anzahl Eintritte-->
                        <div class="-hide" :class="this.abotype != 'coupon'  ? 'hide' : ''">

                        
                        <div class="dx-card-label grid-x align-middle">
                          <div class="auto cell">
                            <p>verbleibende Eintritte</p>
                          </div>
                        </div>

                        <DxNumberBox
                          :id="'numberentries'+this.templateData.data.id"
                          :value="this.numberentries"
                          :input-attr="{ 'aria-label': 'Numberentries' }"
                        />
                        <!-- /Numberentries-->


                        <div v-if="this.templateData.data.CouponEntries">

                          <div v-if="this.templateData.data.CouponEntries.length > 0" class="dx-card-label grid-x align-middle">
                            <div class="auto cell">
                              <p>{{ this.templateData.data.CouponEntries.length}} abgebuchte Eintritte</p>
                            </div>
                          </div>

                          <div v-for="couponentry in this.templateData.data.CouponEntries" :key="couponentry">
                            <div class="summary" style="padding: 5px 10px; border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgb(222, 222, 222);">
                              <p>{{ formatDateTs(couponentry) }}</p>
                            </div>                          
                          </div>

                        </div>


                      </div><!-- /hide-->
                      <!-- /Anzahl Eintritte-->

                    </div><!-- accordion-content -->
                  </li>

                  <li class="accordion-item is-active" data-accordion-item>
                    <a href="#" class="accordion-title -has-border-top">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                          Typ Verlängerung
                        </div>
                      </div>
                    </a>
                    <div class="accordion-content is-active" data-tab-content>
                      <DxSelectBox
                      :id="'remindertype'+this.templateData.data.id"
                      :items="optionreminderlist"
                      :input-attr="{ 'aria-label': 'Remindertype' }"
                      :value="this.remindertype"
                      display-expr="remindername"
                      value-expr="reminderid"
                      />
                      <!-- /Remindertype-->
                    </div><!-- accordion-content -->
                  </li>

                </ul>

              </div>                      
              <!-- dx-card 3 cell two -->


            </div>
            <!-- /cell two-->

            <!-- cell three -->
            <div class="cell cell-margin-y large-3">

              <!-- dx-card 1 cell three-->
              <div class="dx-card no-paddings">

              <div class="dx-card-label -has-border-top grid-x align-middle hide">
                <div class="auto cell">
                  <p>Preise/Raten</p>
                </div>
                <div class="shrink cell">
                  <p>Accordion</p>
                </div>
              </div>

              <ul :id="'accordionSummary'+id" class="accordion is-label" data-accordion>
              <li :id="'accItemSummary'+id" class="accordion-item is-active" data-accordion-item>
                <a href="#" class="accordion-title">
                  <div class="grid-x align-middle" style="height: 100%;">
                    <div class="cell">
                      VG-{{ id }} | {{ rates }} Rate<span v-if="rates > 1">n</span> 
                    </div>
                  </div>
                </a>
                <div class="accordion-content is-active" data-tab-content>

                  <div v-if="!price">
                    <div class="ck-alert" style="padding: 10px;">
                      <p><i class="fa-light fa-triangle-exclamation"></i> Preis/Raten definieren</p>
                    </div>
                  </div>

                  <div v-if="price" class="summary" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">

                    <div class="grid-x grid-margin-x">
                      <div class="shrink cell">
                        <p class="summary-label">Abolaufzeit:</p>
                      </div>
                      <div class="auto cell">
                        <p>
                          <span v-if="validDates.slice(0, 1)">{{ formatDate(validDates.slice(0, 1)) }}</span> 
                          <span v-if="validDates.slice(-1)"> – {{ formatDate(validDates.slice(-1)) }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="grid-x grid-margin-x">
                      <div class="shrink cell">
                        <p class="summary-label">Nettopreis:</p>
                      </div>
                      <div class="auto cell">
                        <p>{{ this.templateData.data.Currency }} {{ formatPrice(price) }}</p>
                      </div>
                    </div>

                    <div v-if="discount > 0" class="grid-x grid-margin-x">
                      <div class="shrink cell">
                        <p class="summary-label">Rabatt:</p>
                      </div>
                      <div class="auto cell">
                        <p>{{ this.templateData.data.Currency }} {{ formatPrice(discount) }}</p>
                      </div>
                    </div>

                    <div  v-if="priceextend * countrates > 0" class="grid-x grid-margin-x">
                      <div class="shrink cell" style="width: 80px;">
                        <div class="shrink cell">
                        <p class="summary-label">Zuschlag:</p>
                      </div>
                      </div>
                      <div class="auto cell">
                        <p>{{ this.templateData.data.Currency }} {{ formatPrice( priceextend * countrates) }}</p>
                      </div>
                    </div>
                    

                    <div class="grid-x grid-margin-x">
                      <div class="shrink cell">
                        <p class="summary-label">Total:</p>
                      </div>
                      <div class="auto cell">
                        <p>{{ this.templateData.data.Currency }} {{ formatPrice( price - discount + (priceextend * countrates)) }}</p>
                      </div>
                    </div>

                    <div v-if="countrates > 1" class="grid-x grid-margin-x">
                      <div class="shrink cell" style="width: 80px;">
                        <div class="shrink cell">
                        <p class="summary-label">Preis je Rate:</p>
                      </div>
                      </div>
                      <div class="auto cell">
                        <p>{{ this.templateData.data.Currency }} {{ formatPrice( (price - discount + (priceextend * countrates)) / rates) }}</p>
                      </div>
                    </div>

                  </div><!-- /if summary-->


                </div>
                <!-- /accordion-content-->


              </li>

              </ul>
              <!-- /accordionSummary -->

              </div>
              <!-- /dx-card 1 cell three-->

              <!-- dx-card 2 cell three -->
              <div class="dx-card no-paddings">

              <ul :id="'accordionInvoice'+id" class="accordion is-label" data-accordion>

                <li class="accordion-item is-active" data-accordion-item>
                  <a href="#" class="accordion-title -has-border-top">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                        Rechnungen
                      </div>
                    </div>
                  </a>
                  <div class="accordion-content is-active" data-tab-content>

                    <!-- accordion-content -->

                    <!-- Rechnungen listen -->

                    <template v-if="arrRates">

                    <!-- legend -->
                    <div class="grid-x -grid-margin-x align-middle row-rates legend">
                      <div class="shrink cell" style="width: 20px; margin-right: 5px;">
                        <div class="ck-overflow-ellipsis hide"><p>id</p></div>
                      </div>
                      <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                        <div class="ck-overflow-ellipsis"><p>Betrag</p></div>
                      </div>
                      <div class="shrink cell" style="width: 120px; margin-right: 5px;">
                        <div class="ck-overflow-ellipsis"><p>Fällig</p></div>
                      </div>
                      <div class="shrink cell" style="width: 100px; margin-right: 10px;">
                        <div class="ck-overflow-ellipsis"><p>Bezahlt</p></div>
                      </div>
                      <div class="auto cell">
                        &nbsp;
                      </div>
                    </div>
                    <!-- /legend -->

                    <!-- listing -->
                    <div v-for="rate in arrRates" :key="rate.id" class="dx-item dx-list-item">
                      <div class="grid-x align-middle row-rates">
                        <div class="shrink cell" style="width: 20px; margin-right: 5px;">
                          <p class="summary-label auto-width">{{ rate.id }}</p>
                        </div>

                        <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                          <p class="hide">
                            <span v-if="rate.Price">{{ this.templateData.data.Currency }} {{ formatPrice(rate.Price) }}</span>
                          </p>

                          <DxNumberBox
                            :id="'PriceRate'+rate.id+id"
                            :value="Math.round(rate.Price * 100) / 100"
                            :input-attr="{ 'aria-label': 'Price' }"
                            height="24"
                          />

                        </div>
                        
                        <div class="shrink cell" style="width: 120px; margin-right: 5px;">

                          <div v-if="rate.Date" class="grid-x align-middle">
                            <div class="shrink cell" style="margin-right: 5px;">

                            <span v-if="functionStatus(rate.Date,rate.Valuta) == 1" class="-grey-color">
                              <i class="fa-light fa-hourglass-clock"></i>
                            </span>
                            <span v-if="functionStatus(rate.Date,rate.Valuta) == 2" class="warning-color">
                              <i class="fa-light fa-triangle-exclamation"></i>
                            </span>
                            <span v-if="functionStatus(rate.Date,rate.Valuta) == 3" class="alert-color">
                              <i class="fa-light fa-triangle-exclamation"></i>
                            </span>
                            <span v-if="functionStatus(rate.Date,rate.Valuta) == 4" class="success-color">
                              <i class="fa-light fa-light fa-circle-check"></i>
                            </span>


                            </div>
                            <div class="auto cell">

                              <DxDateBox
                                :id="'DateRate'+rate.id+id"
                                :value="rate.Date"
                                :input-attr="{ 'aria-label': 'Date' }"
                                displayFormat="dd.MM.yyyy"  
                                type="date"
                                height="24"
                              />

                            </div>
                          </div>

                        </div><!-- cell -->

                        <div class="shrink cell" style="width: 100px; margin-right: 5px;">
                          <p class="hide">
                            <span v-if="rate.Valuta" class="success-color">{{ formatDate(rate.Valuta) }}</span>
                          </p>

                          <DxDateBox
                            :id="'DateValuta'+rate.id+id"
                            :value="rate.Valuta"
                            :input-attr="{ 'aria-label': 'Valuta' }"
                            displayFormat="dd.MM.yyyy"  
                            type="date"
                            height="24"
                          />

                        </div>

                        <div class="auto cell">
                          <!-- auto spacer -->
                        </div>

                        <div class="shrink cell text-right">

                          <div style="display: inline-block; margin-left: 10px;">
                            <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rate.id+'&dest=send'" target="_blank">
                              <i class="fa-light fa-file-pdf"></i>
                            </a>

                          </div>
                          <!-- inline-block -->

                        </div><!-- cell -->

                      </div><!-- grid-x -->
                    </div><!-- dx-list-item -->
                    <!-- /listing -->

                    </template>

                    <div class="dx-item dx-list-item no-border-top -hide" :class="invoiceId  ? 'hide' : ''">
                      <div class="grid-x align-middle" style="padding: 5px 10px;">
                        <div class="auto cell">
                          <a @click="createInvoice" class="cx-button tiny">
                            <i class="fa-light fa-file-circle-plus"></i>
                            &nbsp;Rechnung erstellen
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="dx-item dx-list-item no-border-top" :id="'deleteinvoice'+invoiceId" :class="!invoiceId  ? 'hide' : ''">
                      <div class="grid-x align-middle" style="padding: 5px 10px;">
                        <div class="auto cell">
                          <a @click="deleteInvoice" class="cx-button tiny">
                            <i class="fa-light fa-file-circle-minus"></i>
                            &nbsp;Rechnung löschen
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="dx-item dx-list-item no-border-top hide" :id="'deleteinvoicetrue'+invoiceId" :class="!invoiceId  ? 'hide' : ''">

                      <div style="padding: 5px 10px;">
                        <p class="alert-color">Sind sie sicher das sie die Rechnung RE-{{invoiceId }} löschen wollen!</p>
                      </div>


                      <div class="grid-x align-middle" style="padding: 5px 10px;">
                        <div class="shrink cell" style="margin-right: 5px;">
                          <a @click="deleteInvoicetrue" class="cx-button tiny">
                            Löschen
                          </a>
                        </div>
                        <div class="shrink cell">
                          <a @click="deleteInvoicefalse" class="cx-button tiny">
                            Abbrechen
                          </a>
                        </div>
                        <div class="auto cell">
                        </div>

                      </div>
                    </div><!-- dx-item -->

                  </div><!-- accordion-content -->
                </li>
              </ul>

              </div>                      
              <!-- dx-card 2 cell three -->


              <!-- dx-card 3 cell three -->
              <div class="dx-card no-paddings">

                <ul :id="'accordionSignature'+id" class="accordion is-label" data-accordion>

                  <li class="accordion-item -is-active" data-accordion-item>
                    <a href="#" class="accordion-title -has-border-top">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                          <span v-if="!signaturepng" class="alert-color"><i class="fa-light fa-triangle-exclamation"></i> </span>
                          <span v-if="signaturepng" class="success-color"><i class="fa-light fa-circle-check"></i> </span>
                          Unterschrift Kunde
                        </div>
                      </div>
                    </a>
                    <div class="accordion-content -is-active" data-tab-content>

                      <div v-if="!signaturepng">
                        <div class="ck-alert" style="padding: 10px;">
                          <p><i class="fa-light fa-triangle-exclamation"></i> Unterschrift definieren</p>
                        </div>
                      </div>

                      <div v-if="signaturepng" class="content-wrap" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">

                      <!-- Unterschrift Kunde (signature.png) -->
                      <div class="signature-image">
                        <img :src="signaturepng+'?v=' + timestamp" />
                      </div><!-- /signature-image -->

                      <div class="signature-controls-save">
                        <!-- controls save -->
                        <ul class="menu horizontal">
                          <li>
                            <button type="button" class="cx-button tiny" @click.prevent="removeSavedStrokes()"><i class="fa-light fa-trash"></i> Unterschrift löschen</button>
                          </li>
                        </ul>
                      </div><!-- /signature-controls-save -->
                        
                    </div><!-- /content-wrap -->

                    </div> <!-- /accordion-content-->
                  </li>

                  <li class="accordion-item" data-accordion-item>
                    <a href="#" class="accordion-title -has-border-top">
                      <div class="grid-x align-middle" style="height: 100%;">
                        <div class="cell">
                          Unterschreiben Kunde
                        </div>
                      </div>
                    </a>
                    <div class="accordion-content" data-tab-content>
                      <!-- <p>Unterschreiben Kunde (signature-canvas)</p>  -->

                      <div class="content-wrap" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">

                        <div class="signature-controls-edit" style="margin-bottom: 5px;">
                        <!-- controls edit -->

                          <ul class="menu horizontal">
                            <li>
                              <button type="button" @click.prevent="disabled = !disabled">
                              <span v-if="!disabled"><i class="fa-light fa-unlock"></i> Unlock</span>
                              <span v-else><i class="fa-light fa-lock"></i> Lock</span>
                              </button>
                            </li>
                            <li>
                              <button type="button" @click.prevent="$refs.VueCanvasDrawing.undo()"><i class="fa-light fa-arrow-rotate-left"></i> Undo</button>
                            </li>
                            <li>
                              <button type="button" @click.prevent="$refs.VueCanvasDrawing.redo()"><i class="fa-light fa-arrow-rotate-right"></i> Redo</button>
                            </li>
                            <li>
                              <button type="button" @click.prevent="$refs.VueCanvasDrawing.redraw()"><i class="fa-light fa-arrows-rotate"></i> Redraw</button>
                            </li>
                            <li>
                              <button type="button" @click.prevent="$refs.VueCanvasDrawing.reset()"><i class="fa-light fa-eraser"></i> Reset</button>
                            </li>
                          </ul>
                        </div><!-- /signature-controls-edit -->

                        <div class="signature-canvas">
                        
                          <vue-drawing-canvas
                            ref="VueCanvasDrawing"
                            :canvasId="'VueDrawingCanvas'+context+id"
                            v-model:image="image"
                            :width="300"
                            :height="200"
                            :stroke-type="strokeType"
                            :line-cap="lineCap"
                            :line-join="lineJoin"
                            :fill-shape="fillShape"
                            :eraser="eraser"
                            :lineWidth="line"
                            :color="color"
                            :background-color="backgroundColor"
                            :background-image="backgroundImage"
                            :watermark="watermark"
                            :initial-image="initialImage"
                            saveAs="png"
                            :styles="{
                            border: 'solid 1px #dedede',
                            }"
                            :lock="disabled"
                            @mousemove="getCoordinate($event)"
                            :additional-images="additionalImages"
                          />

                        </div><!-- /signature-canvas -->

                        <div class="signature-image hide">
                          <img :src="image" />
                        </div><!-- /signature-image -->

                        <div class="signature-controls-save">
                          <!-- controls save -->
                          <ul class="menu horizontal">
                            <li>
                              <button type="button" class="cx-button tiny" @click.prevent="getStrokes()"><i class="fa-light fa-floppy-disk"></i> Unterschrift sichern</button>
                            </li>
                          </ul>
                        </div><!-- /signature-controls-save -->


                      </div><!-- /content-wrap -->


                    </div> <!-- /accordion-content-->
                  </li>

                </ul>

              </div>
              <!-- dx-card 3 cell three-->

              <!-- dx-card 4 cell three -->
              <div class="dx-card no-paddings">

                <ul :id="'accordionDocument'+id" class="accordion is-label" data-accordion>
                <li :id="'accItemDocument'+id" class="accordion-item is-active" data-accordion-item>
                  <a href="#" class="accordion-title">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                        Dokumente (PDF)
                      </div>
                    </div>
                  </a>
                  <div class="accordion-content is-active" data-tab-content>

                    <!-- pdf Vertrag-->
                    <div class="summary" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/contract/'+customerId+'/'+id+'&dest=send'" target="_blank">
                        <i class="fa-light fa-file-pdf"></i> Vertrag-{{id}}.pdf
                      </a>
                    </div>
                    <!-- /pdf Vertrag-->

                    <!-- pdf Fitnessguide-->
                    <div v-if="fitnessguidepdf" class="summary" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/fitnessguide/'+customerId+'/'+id+'&dest=send'" target="_blank">
                      <i class="fa-light fa-file-pdf"></i> Fitnessguide-{{id}}.pdf
                      </a>
                    </div>
                    <!-- /pdf Fitnessguide-->

                    <!-- pdf Qualicert-->
                    <div v-if="qualicertpdf" class="summary" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/qualicert/'+customerId+'/'+id+'&dest=send'" target="_blank">
                        <i class="fa-light fa-file-pdf"></i> Qualicert-{{id}}.pdf
                      </a>
                    </div>
                    <!-- /pdf Qualicert-->

                    <!-- pdf Qualitop-->
                    <div v-if="qualitoppdf" class="summary" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/qualitop/'+customerId+'/'+id+'&dest=send'" target="_blank">
                        <i class="fa-light fa-file-pdf"></i> Qualitop-{{id}}.pdf
                      </a>
                    </div>
                    <!-- /pdf Qualitop-->

                    <!-- pdf Rechnungen-->
                    <div v-for="rate in arrRates" :key="rate.id" class="summary" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rate.id+'&dest=send'" target="_blank">
                        <i class="fa-light fa-file-pdf"></i> Rechnung-{{ invoiceId }}-{{ rate.id }}.pdf
                      </a>
                    </div>
                    <!-- /pdf Rechnungen-->

                  </div><!-- /accordion-content-->

                </li>

                </ul>


                </div>
                <!-- /dx-card 4 cell three-->

                <!-- dx-card 5 cell three -->
                <div class="dx-card no-paddings">

              <!-- Bemerkungen -->
              <div class="dx-card-label -has-border-top grid-x align-middle -hide">
                <div class="auto cell">
                  <p>Bemerkungen</p>
                </div>
                <div class="shrink cell">
                  <p>&nbsp;</p>
                </div>
              </div>

              <DxTextBox
                :id="'Description'+id"
                :value="this.templateData.data.Description"
                :input-attr="{ 'aria-label': 'Description' }"
              />
              <!-- /Bemerkungen -->

              <!-- Betreff hide -->
              <div class="hide">

              <label class="ck-label hide">Betreff</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskSubject }}</p>
                  </div>
                </div>

                <ul :id="'tabsLang'+id" class="tabs" data-tabs>
                  <li class="tabs-title" :class="currentUser.Language == 'de'  ? 'is-active' : ''"><a :href="'#panelSubject_de'+this.templateData.data.id" aria-selected="true"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/de.svg'"><span class="hide">de</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'en'  ? 'is-active' : ''"><a :href="'#panelSubject_en'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/gb.svg'"><span class="hide">en</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'fr'  ? 'is-active' : ''"><a :href="'#panelSubject_fr'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/fr.svg'"><span class="hide">fr</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'es'  ? 'is-active' : ''"><a :href="'#panelSubject_es'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/es.svg'"><span class="hide">es</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'it'  ? 'is-active' : ''"><a :href="'#panelSubject_it'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/it.svg'"><span class="hide">it</span></a></li>
                </ul>


                <div class="tabs-content" :data-tabs-content="'tabsLang'+id">

                  <div class="tabs-panel" :class="currentUser.Language == 'de'  ? 'is-active' : ''" :id="'panelSubject_de'+id">
                    <DxTextBox
                      :id="'Subject_de'+id"
                      :value="this.templateData.data.Subject_de"
                      :input-attr="{ 'aria-label': 'Subject_de' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'en'  ? 'is-active' : ''" :id="'panelSubject_en'+id">
                    <DxTextBox
                      :id="'Subject_en'+id"
                      :value="this.templateData.data.Subject_en"
                      :input-attr="{ 'aria-label': 'Subject_en' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'fr'  ? 'is-active' : ''" :id="'panelSubject_fr'+id">
                    <DxTextBox
                      :id="'Subject_fr'+id"
                      :value="this.templateData.data.Subject_fr"
                      :input-attr="{ 'aria-label': 'Subject_fr' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'es'  ? 'is-active' : ''" :id="'panelSubject_es'+id">
                    <DxTextBox
                      :id="'Subject_es'+id"
                      :value="this.templateData.data.Subject_es"
                      :input-attr="{ 'aria-label': 'Subject_es' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'it'  ? 'is-active' : ''" :id="'panelSubject_it'+id">
                    <DxTextBox
                      :id="'Subject_it'+id"
                      :value="this.templateData.data.Subject_it"
                      :input-attr="{ 'aria-label': 'Subject_it' }"
                    />
                  </div>

                </div><!-- /tabs-content-->

              </div><!-- /Betreff hide-->

              </div>
              <!-- /dx-card 5 cell three -->


            </div><!-- /cell three-->

          </div><!-- grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid -->
          
        </div><!-- master-form -->

      </template><!-- mastercontent -->
    
      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
        css-class="hide"
      />

    </DxForm>

  </div><!-- hide -->

  </form>

</template>

<script>

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  //DxGroupItem,
  //DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxPatternRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

//import { DxRadioGroup } from 'devextreme-vue/radio-group';

import DxList, { DxSearchEditorOptions } from 'devextreme-vue/list';

import notify from 'devextreme/ui/notify';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import DxCalendar from 'devextreme-vue/calendar';
import DxSelectBox from 'devextreme-vue/select-box';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import { ref } from 'vue';

import VueDrawingCanvas from "vue-drawing-canvas";

import auth from "../auth";

import { 
  apihost, 
  pdfhost,
} from "../api";
//console.log(apihost);
//console.log(pdfhost);

const initialValue = new Date();
const minDateValue = ref(null);
const maxDateValue = ref(null);
const selectionMode = ref('multiple');

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

const optionabotypelist = [ 
  { "typeid": 'time', "typename": "Zeitraum" },
  { "typeid": 'coupon', "typename": "Coupon" } 
];
//console.log(optionabotypelist);

const optionreminderlist = [ 
  { "reminderid": 'notextend', "remindername": "anfragen" },
  { "reminderid": 'autoextend', "remindername": "automatisch" },
  { "reminderid": 'sendmail', "remindername": "nicht automatisch" } 
];
//console.log(optionreminderlist);

let currentUser;
let timestamp;

let disabledDates = [];

let context;
let memberList = [];
let employeeList = [];
let aboList = [];

let id;
let customerId;
let employeeId;
let aboId;

let savedaboId;

let locationId;
let invoiceId;

let customerSubject;
let customerPicthumb;

let employeeSubject;
let employeePicthumb;

let aboSubject;
let aboPicthumb;

let arrRates;

let dateStart;
let dateStop;

let dateStart1;
let dateStop1;
let dateStart2;
let dateStop2;
let dateStart3;
let dateStop3;
let dateStart4;
let dateStop4;
let dateStart5;
let dateStop5;

let deskBreak1;
let deskBreak2;
let deskBreak3;
let deskBreak4;
let deskBreak5;

let dayExt1;
let dayExt2;
let dayExt3;
let dayExt4;
let dayExt5;

let textExt1;
let textExt2;
let textExt3;
let textExt4;
let textExt5;

let dateInvoice;

let validDates;
let arrValiddates;

let price;
let discount;
let rates;
let countrates;
let interval;


let priceextend;
let abotype;
let remindertype;
let numberentries;


let contractpdf;
let signaturepng;
let fitnessguidepdf;
let qualicertpdf;
let qualitoppdf;

let invoicedate;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
   if (!response.ok) {
       throw Error(response.statusText);
   }
   return response;
 }


export default {
 name: 'contractitem-template',

 props: {
  templateData: Object,
 },

 components: {
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxDateBox,
  DxTextBox,
  DxNumberBox,
  DxList,
  DxSearchEditorOptions,
  DxCalendar,
  DxSelectBox,

  VueDrawingCanvas,

 },

 mounted() {

  this.context = this.templateData.data.Context;


  this.memberList = this.templateData.data.Memberlist;
  this.employeeList = this.templateData.data.Employeelist;
  this.aboList = this.templateData.data.Abolist;

  this.id = this.templateData.data.id;
  this.customerId = this.templateData.data.Customerid;
  this.employeeId = this.templateData.data.Employeeid;
  this.aboId = this.templateData.data.Aboid;
  this.savedaboId = this.templateData.data.Aboid
  this.locationId = this.templateData.data.LocationId;
  this.invoiceId = this.templateData.data.Invoiceid;

  this.customerSubject = this.templateData.data.CustomerSubject;
  this.customerPicthumb = this.templateData.data.CustomerPicthumb;

  this.employeeSubject = this.templateData.data.EmployeeSubject;
  this.employeePicthumb = this.templateData.data.EmployeePicthumb;

  this.aboSubject = this.templateData.data.AboSubject;
  this.aboPicthumb = this.templateData.data.AboPicthumb;

  this.arrRates = this.templateData.data.RateList;

  this.dateStart = this.templateData.data.DateStart;
  this.dateStop = this.templateData.data.DateStop;
  this.dateStart1 = this.templateData.data.DateStart1;
  this.dateStop1 = this.templateData.data.DateStop1;
  this.dateStart2 = this.templateData.data.DateStart2;
  this.dateStop2 = this.templateData.data.DateStop2;
  this.dateStart3 = this.templateData.data.DateStart3;
  this.dateStop3 = this.templateData.data.DateStop3;
  this.dateStart4 = this.templateData.data.DateStart4;
  this.dateStop4 = this.templateData.data.DateStop4;
  this.dateStart5 = this.templateData.data.DateStart5;
  this.dateStop5 = this.templateData.data.DateStop5;

  this.deskBreak1 = this.templateData.data.DeskBreak1;
  this.deskBreak2 = this.templateData.data.DeskBreak2;
  this.deskBreak3 = this.templateData.data.DeskBreak3;
  this.deskBreak4 = this.templateData.data.DeskBreak4;
  this.deskBreak5 = this.templateData.data.DeskBreak5;

  this.dayExt1 = this.templateData.data.ExtendDay1;
  this.dayExt2 = this.templateData.data.ExtendDay2;
  this.dayExt3 = this.templateData.data.ExtendDay3;
  this.dayExt4 = this.templateData.data.ExtendDay4;
  this.dayExt5 = this.templateData.data.ExtendDay5;

  this.textExt1 = this.templateData.data.ExtendText1;
  this.textExt2 = this.templateData.data.ExtendText2;
  this.textExt3 = this.templateData.data.ExtendText3;
  this.textExt4 = this.templateData.data.ExtendText4;
  this.textExt5 = this.templateData.data.ExtendText5;

  this.dateInvoice = this.templateData.data.DateInvoice;
  this.validDates = this.templateData.data.ValidDates;

  this.price = this.templateData.data.Price;
  this.discount = this.templateData.data.Discount;
  this.rates = this.templateData.data.Rates;
  this.interval = this.templateData.data.Interval;
  this.priceextend = this.templateData.data.Priceextend;
  this.abotype = this.templateData.data.Abotype;
  this.remindertype = this.templateData.data.Remindertype;
  this.numberentries = this.templateData.data.Numberentries;


  if(this.templateData.data.Rates > 0){
    this.countrates = this.templateData.data.Rates -1;
  }

  this.contractpdf = this.templateData.data.ContractPdf;
  this.signaturepng = this.templateData.data.SignaturePng;
  this.fitnessguidepdf = this.templateData.data.FitnessguidePdf;
  this.qualicertpdf = this.templateData.data.QualicertPdf;
  this.qualitoppdf = this.templateData.data.QualitopPdf;

  this.invoicedate = this.templateData.data.InvoiceDate;

  arrValiddates = [];
  if(this.validDates){
    let datesValid = this.validDates;
    //console.log(validdates);
    for(var i in datesValid){
      //console.log(validdates[i]);
      let date = new Date(datesValid[i]);
      //console.log(date);
      arrValiddates.push(date);
    }

  }
  //console.log(arrValiddates);
  this.disabledDates = arrValiddates;

  //foundation
  this.tabsLang = new Foundation.Tabs($('#tabsLang'+id), {
    matchHeight: false
  });

  this.accordionMember = new Foundation.Accordion($('#accordionMember'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: false,
    allowAllClosed: true,
  });

  this.accordionEmployee = new Foundation.Accordion($('#accordionEmployee'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: false,
    allowAllClosed: true,
  });

  this.accordionAbo = new Foundation.Accordion($('#accordionAbo'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: false,
    allowAllClosed: true,
  });

  this.accordionLocation = new Foundation.Accordion($('#accordionLocation'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: true,
    allowAllClosed: true,
  });

  this.accordionPrices = new Foundation.Accordion($('#accordionPrices'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: true,
    allowAllClosed: true,
  });

  this.accordionRates = new Foundation.Accordion($('#accordionRates'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: true,
    allowAllClosed: true,
  });
  
  this.accordionSummary = new Foundation.Accordion($('#accordionSummary'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: true,
    allowAllClosed: true,
  });

  this.accordionPrice = new Foundation.Accordion($('#accordionPrice'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: true,
    allowAllClosed: true,
  });

  this.accordionDates = new Foundation.Accordion($('#accordionDates'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: true,
    allowAllClosed: true,
  });

  this.accordionSignature = new Foundation.Accordion($('#accordionSignature'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: false,
    allowAllClosed: true,
  });

  this.accordionDocument = new Foundation.Accordion($('#accordionDocument'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: false,
    allowAllClosed: true,
  });

  this.accordionType = new Foundation.Accordion($('#accordionType'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: true,
    allowAllClosed: true,
  });

  this.accordionInvoice = new Foundation.Accordion($('#accordionInvoice'+id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: false,
    allowAllClosed: true,
  });
  

  this.timestamp = this.printTimestamp();
  

},

 data() {
   return {

    apihost,
    pdfhost,

    timestamp,

    currentUser,
    colCountByScreen,
    optionabotypelist,
    optionreminderlist,

    context,
    memberList,
    employeeList,
    aboList,

    id,
    customerId,
    employeeId,
    aboId,
    savedaboId,
    locationId,

    customerSubject,
    customerPicthumb,

    employeeSubject,
    employeePicthumb,

    aboSubject,
    aboPicthumb,

    arrRates,
    invoiceId,

    dateStart,
    dateStop,
    dateStart1,
    dateStop1,
    dateStart2,
    dateStop2,
    dateStart3,
    dateStop3,
    dateStart4,
    dateStop4,
    dateStart5,
    dateStop5,

    deskBreak1,
    deskBreak2,
    deskBreak3,
    deskBreak4,
    deskBreak5,

    dayExt1,
    dayExt2,
    dayExt3,
    dayExt4,
    dayExt5,

    textExt1,
    textExt2,
    textExt3,
    textExt4,
    textExt5,

    dateInvoice,

    minDateValue,
    maxDateValue,
    initialValue,
    disabledDates,
    selectionMode,

    validDates,

    price,
    discount,
    rates,
    countrates,
    interval,
    priceextend,
    abotype,
    remindertype,
    numberentries,

    contractpdf,
    signaturepng,
    fitnessguidepdf,
    qualicertpdf,
    qualitoppdf,

    invoicedate,

    //save Button
    buttonOptions: {
      text: currentUser.Translation.vueappContractFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },



    //CANVAS
    initialImage: [
      {
        type: "dash",
        from: {
          x: 262,
          y: 154,
        },
        coordinates: [],
        color: "#000000",
        width: 5,
        fill: false,
      },
    ],

    x: 0,
    y: 0,
    image: "",
    eraser: false,
    disabled: false,
    fillShape: false,
    line: 3,
    color: "#000000",
    strokeType: "dash",
    lineCap: "round",
    lineJoin: "round",
    backgroundColor: "#FFFFFF",
    backgroundImage: null,
    watermark: null,
    additionalImages: [],

    //END CANVAS

    
   }; //return

 },
 methods: {

  functionStatus(dateInvoice, dateValuta){

    const today = new Date().getTime();
    const invoice = new Date(dateInvoice).getTime();
    const valuta = dateValuta;
    let status = 0;

    if (today <= invoice) {
      //console.log('hourglass');
      status = 1;
    }

    if (today > invoice) {
      //console.log('warning');
      status = 2;
    }

    if (today > invoice ) {
      //console.log('alert');
      status = 3;
    }

    if(valuta){
      //console.log('success');
      status = 4;
    }

    return status;

  },



  formatDateTs(ts) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(ts);
    return new Intl.DateTimeFormat('default', {dateStyle: 'medium',timeStyle: 'medium'}).format(date);
  },

  formatDate(dateString) {
    const date = new Date(dateString);
    // Then specify how you want your dates to be formatted
    //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
  },

  formatPrice(value) {
    //https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
    let val = (value/1).toFixed(2).replace('.', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  },

  async setImage(event) {
    console.log(event);
      let URL = window.URL;
      this.backgroundImage = URL.createObjectURL(event.target.files[0]);
      await this.$refs.VueCanvasDrawing.redraw();
    },
    async setWatermarkImage(event) {
      let URL = window.URL;
      this.watermark = {
        type: "Image",
        source: URL.createObjectURL(event.target.files[0]),
        x: 0,
        y: 0,
        imageStyle: {
          width: 600,
          height: 400,
        },
      };
      await this.$refs.VueCanvasDrawing.redraw();
    },
    getCoordinate(event) {
      //console.log(event);
      let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
      this.x = coordinates.x;
      this.y = coordinates.y;
    },

    getStrokes() {

      window.localStorage.setItem(
        "vue-drawing-canvas",
        JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
      );
      //console.log("Strokes saved, reload your browser to see the canvas with previously saved image")

      const action = 'contractsave';
      const baseUrl  = apihost+'/de/vue/signature/'+this.customerId+'/'+this.id+'/'+action;
      const dataURL = this.image;

      $.ajax({
      type: "POST",
      url: baseUrl,
      data: { 
         imgBase64: dataURL
      }
      }).done(function() {
        // If you want the file to be visible in the browser 
        // - please modify the callback in javascript. All you
        // need is to return the url to the file, you just saved 
        // and than put the image in your browser.
      });      

      this.timestamp = Date.now();

      setTimeout(() => {
        this.signaturepng = apihost+'/contractsignature/'+this.id+'.png?v='+Date.now();
      }, "3000");

      notify({
          message: "Unterschrift wurde gesichert",
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);

    },
    
    removeSavedStrokes() {

      window.localStorage.removeItem("vue-drawing-canvas");
      //console.log("Strokes cleared from local storage"); 

      const action = 'contractdelete';

      const baseUrl  = apihost+'/de/vue/signature/'+this.customerId+'/'+this.id+'/'+action;
      //console.log(baseUrl);

      $.ajax({
      type: "POST",
      url: baseUrl,
      data: { 
         imgBase64: ''
      }
      }).done(function() {
        //console.log("Strokes saved, reload your browser to see the canvas with previously saved image");
        // If you want the file to be visible in the browser 
        // - please modify the callback in javascript. All you
        // need is to return the url to the file, you just saved 
        // and than put the image in your browser.
      });      

      notify({
        message: "Unterschrift wurde gelöscht",
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);
      
      this.signaturepng = '';

  },


  printTimestamp: function () {
    return Date.now();
  },

  openaccordion(target){
    //console.log(target);
    //open accordion on click
    const trigger = document.getElementById(target+'-label');
    //const trigger = document.getElementById('accItemAbo2865-label');
    //console.log(trigger);
    if(trigger){
      trigger.click();
    }
    
  },
    
  selectmember(e) {
    // `e` is the native DOM event
    if (e) {
      //console.log(e);
      //console.log(e.target.id);

      const memberContainer = e.target.id.replace('memberButton','memberContainer');
      const memberID = document.getElementById(memberContainer).dataset.id;
      const locationID = document.getElementById(memberContainer).dataset.locationid;

      if(memberID){
        this.customerId = memberID;
      }
      if(locationID){
        this.locationId = parseInt(locationID) ;
      }

      //close accordion on click
      const trigger = document.getElementById('accItemMember'+id+'-label');
      //console.log(trigger);
      trigger.click();
    }

  },

  selectemployee(e) {
    // `e` is the native DOM event
    if (e) {
      //console.log(e);
      //console.log(e.target.id);

      const employeeContainer = e.target.id.replace('employeeButton','employeeContainer');
      const employeeID = document.getElementById(employeeContainer).dataset.id;
      if(employeeID){
        this.employeeId = employeeID;
      }
      //close accordion on click
      const trigger = document.getElementById('accItemEmployee'+id+'-label');
      //console.log(trigger);
      trigger.click();

    }

  },

  selectabo(e) {
    // `e` is the native DOM event
    if (e) {
      //console.log(e);
      //console.log(e.target.id);
      const aboContainer = e.target.id.replace('aboButton','aboContainer');
      const aboID = document.getElementById(aboContainer).dataset.id;
      const price = document.getElementById(aboContainer).dataset.price;
      const daysvalid = document.getElementById(aboContainer).dataset.daysvalid;
      const priceextend = document.getElementById(aboContainer).dataset.priceextend;

      const abotype = document.getElementById(aboContainer).dataset.abotype;
      const remindertype = document.getElementById(aboContainer).dataset.remindertype;
      const numberentries = document.getElementById(aboContainer).dataset.numberentries;

      //set discount to zero
      this.discount = 0;
      //set abotype
      this.abotype = abotype;
      //set numberentries
      this.numberentries = parseInt(numberentries);

      const newDate = new Date(this.dateStart);
      newDate.setDate(newDate.getDate() + parseFloat(daysvalid));

      //set dateStop
      if(daysvalid){
        this.dateStop = newDate.toISOString();
      }

      //set aboID
      if(aboID){
        this.aboId = aboID;
      }

      //set price
      if(price){
        this.price = parseFloat(price);
      } else {
        this.price = 0;
      }

      //set priceextend
      if(priceextend){
        this.priceextend = parseFloat(priceextend);
      } else {
        this.priceextend = 0;
      }

      //set abotype
      if(abotype){
        this.abotype = abotype;
      } else {
        this.abotype = '';
      }

      //set remindertype
      if(remindertype){
        this.remindertype = remindertype;
      } else {
        this.remindertype = '';
      }

      //close accordion on click
      const trigger = document.getElementById('accItemAbo'+id+'-label');
      //console.log(trigger);
      trigger.click();

    }

  },

  priceBoxValueChanged(e) {
    this.price = e.value;
  },

  discountBoxValueChanged(e) {
    this.discount = e.value;
  },

  priceextendBoxValueChanged(e) {
    this.priceextend = e.value;
  },

  ratesBoxValueChanged(e) {
    this.rates = e.value;
    if(e.value > 1){
      this.countrates = e.value;
    } else {
      this.countrates = 0;
    }
  },

  setdatestart(e) {
    //const previousValue = e.previousValue;
    //console.log(previousValue);
    const newValue = e.value;
    //console.log(newValue);
    //console.log(this.templateData.data.Duedays);

    let days = this.templateData.data.Duedays;
    let duedate = new Date(newValue);
    duedate.setDate(duedate.getDate() + days);
    //console.log(duedate);

    //Set dateInvoice (Fällig 1. Rate)
    this.dateInvoice = duedate;
    
  },

  setdatestop() {

    const daysvalid = document.getElementById("inputdaysvalid"+this.aboId).value;
    const getDateStart = document.getElementById("DateStart"+this.id).getElementsByTagName("input")[0].value;
    const newDate = new Date(getDateStart);

   //set newDate
    newDate.setDate(newDate.getDate() + parseFloat(daysvalid));

    //set dateStop
    if(daysvalid){
      this.dateStop = newDate.toISOString();
    }

  },

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  deleteInvoice() {
    const elemdelete = document.getElementById("deleteinvoice"+this.invoiceId);
    const elemdeletetrue = document.getElementById("deleteinvoicetrue"+this.invoiceId);
    //toggle Class hide
    elemdelete.classList.add("hide");
    elemdeletetrue.classList.remove("hide");
  },

  deleteInvoicefalse() {
    const elemdelete = document.getElementById("deleteinvoice"+this.invoiceId);
    const elemdeletetrue = document.getElementById("deleteinvoicetrue"+this.invoiceId);
    //toggle Class hide
    elemdelete.classList.remove("hide");
    elemdeletetrue.classList.add("hide");
  },

  deleteInvoicetrue() {

    let objString = apihost+'/de/vue/invoice/getinvoice/'+this.invoiceId+'/remove';

    fetch(objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      console.log(data);

      //update Frontend
      this.arrRates = '';
      this.invoiceId = '';
    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappInvoiceFormSubmitSuccess,

      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

  },


  createInvoice() {

    this.timestamp = this.printTimestamp();

    //fallback invoicedate today
    const todayArray = new Date().toISOString().split("T");
    const invoicedate = todayArray[0]+'T00:00:00+01:00';
    let startdate = invoicedate;
    //let dateforInvoice = '2024-02-01T00:00:00+01:00';

    const DateInvoice = document.getElementById("DateInvoice"+this.id).getElementsByTagName("input")[0].value;
    if(DateInvoice){
      startdate = DateInvoice+'T00:00:00+01:00';
    } else {
      if(this.dateInvoice){
        startdate = this.dateInvoice;
      }
    }
    //console.log(dateforInvoice);

    let priceinvoice = this.price - this.discount + (this.priceextend * this.countrates);

    let objString = apihost+'/de/vue/invoice/getinvoice/0/insert';

    objString = objString + '?Contractid=' + this.id;
    objString = objString + '&Customerid=' + this.customerId;
    objString = objString + '&Price=' + priceinvoice;
    objString = objString + '&Rates=' + this.rates;
    objString = objString + '&Interval=' + this.interval;

    objString = objString + '&DateInvoice=' + invoicedate;
    objString = objString + '&DateStart=' + startdate;
    objString = objString + '&Subject_de=' + 'subde';
    objString = objString + '&Subject_en=' + 'suben';
    objString = objString + '&Subject_fr=' + 'subfr';
    objString = objString + '&Subject_es=' + 'subes';
    objString = objString + '&Subject_it=' + 'subit';
    objString = objString + '&Description=' + 'desc';

    //objString = objString + '&DateInvoice=' + this.templateData.data.DateInvoice;
    //objString = objString + '&DateStart=' + dateStart;
    //console.log(objString);

    this.arrRates = [];

    fetch(objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);

      //update Frontend
      this.arrRates = data.arrRates;
      this.invoiceId = data.Invoiceid;
    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappInvoiceFormSubmitSuccess,

      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

  },

  handleSubmit(e) {
    e.preventDefault();

    this.timestamp = this.printTimestamp();

    const id = this.formInstance.getEditor('id').option('value'); 
    // const Status = this.formInstance.getEditor('Status').option('value');  
    // const Priority = this.formInstance.getEditor('Priority').option('value');  

    const Subject_de = document.getElementById("Subject_de"+id).getElementsByTagName("input")[0].value;
    const Subject_en = document.getElementById("Subject_en"+id).getElementsByTagName("input")[0].value;
    const Subject_fr = document.getElementById("Subject_fr"+id).getElementsByTagName("input")[0].value;
    const Subject_es = document.getElementById("Subject_es"+id).getElementsByTagName("input")[0].value;
    const Subject_it = document.getElementById("Subject_it"+id).getElementsByTagName("input")[0].value;

    const Description = document.getElementById("Description"+id).getElementsByTagName("input")[0].value;

    const Price = document.getElementById("Price"+id).getElementsByTagName("input")[0].value;
    const Discount = document.getElementById("Discount"+id).getElementsByTagName("input")[0].value;
    const Rates = document.getElementById("Rates"+id).getElementsByTagName("input")[0].value;
    const Interval = document.getElementById("Interval"+id).getElementsByTagName("input")[0].value;
    const Priceextend = document.getElementById("Priceextend"+id).getElementsByTagName("input")[0].value;

    const DateStart = document.getElementById("DateStart"+id).getElementsByTagName("input")[0].value;
    const DateStop = document.getElementById("DateStop"+id).getElementsByTagName("input")[0].value;

    const DateStart1 = document.getElementById("DateStart1"+id).getElementsByTagName("input")[0].value;
    const DateStop1 = document.getElementById("DateStop1"+id).getElementsByTagName("input")[0].value;

    const DateStart2 = document.getElementById("DateStart2"+id).getElementsByTagName("input")[0].value;
    const DateStop2 = document.getElementById("DateStop2"+id).getElementsByTagName("input")[0].value;

    const DateStart3 = document.getElementById("DateStart3"+id).getElementsByTagName("input")[0].value;
    const DateStop3 = document.getElementById("DateStop3"+id).getElementsByTagName("input")[0].value;

    const DateStart4 = document.getElementById("DateStart4"+id).getElementsByTagName("input")[0].value;
    const DateStop4 = document.getElementById("DateStop4"+id).getElementsByTagName("input")[0].value;

    const DateStart5 = document.getElementById("DateStart5"+id).getElementsByTagName("input")[0].value;
    const DateStop5 = document.getElementById("DateStop5"+id).getElementsByTagName("input")[0].value;

    const DeskBreak1 = document.getElementById("DeskBreak1"+id).getElementsByTagName("input")[0].value;
    const DeskBreak2 = document.getElementById("DeskBreak2"+id).getElementsByTagName("input")[0].value;
    const DeskBreak3 = document.getElementById("DeskBreak3"+id).getElementsByTagName("input")[0].value;
    const DeskBreak4 = document.getElementById("DeskBreak4"+id).getElementsByTagName("input")[0].value;
    const DeskBreak5 = document.getElementById("DeskBreak5"+id).getElementsByTagName("input")[0].value;

    const ExtendDay1 = document.getElementById("ExtendDay1"+id).getElementsByTagName("input")[0].value;
    const ExtendDay2 = document.getElementById("ExtendDay2"+id).getElementsByTagName("input")[0].value;
    const ExtendDay3 = document.getElementById("ExtendDay3"+id).getElementsByTagName("input")[0].value;
    const ExtendDay4 = document.getElementById("ExtendDay4"+id).getElementsByTagName("input")[0].value;
    const ExtendDay5 = document.getElementById("ExtendDay5"+id).getElementsByTagName("input")[0].value;

    const ExtendText1 = document.getElementById("ExtendText1"+id).getElementsByTagName("input")[0].value;
    const ExtendText2 = document.getElementById("ExtendText2"+id).getElementsByTagName("input")[0].value;
    const ExtendText3 = document.getElementById("ExtendText3"+id).getElementsByTagName("input")[0].value;
    const ExtendText4 = document.getElementById("ExtendText4"+id).getElementsByTagName("input")[0].value;
    const ExtendText5 = document.getElementById("ExtendText5"+id).getElementsByTagName("input")[0].value;

    const DateInvoice = document.getElementById("DateInvoice"+id).getElementsByTagName("input")[0].value;
    const LocationId = document.getElementById("Location"+id).getElementsByTagName("input")[0].value;

    const Abotype = document.getElementById("abotype"+id).getElementsByTagName("input")[0].value;
    const Remindertype = document.getElementById("remindertype"+id).getElementsByTagName("input")[0].value;
    const Numberentries = document.getElementById("numberentries"+id).getElementsByTagName("input")[0].value;

    //console.log(Numberentries);


    //https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset

    let getdateStart;
    let isoDateStart;
    if(DateStart){
      getdateStart = new Date(DateStart);
      isoDateStart = new Date(getdateStart.getTime() - (getdateStart.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStop;
    let isoDateStop;
    if(DateStop){
      getdateStop = new Date(DateStop);
      isoDateStop = new Date(getdateStop.getTime() - (getdateStop.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStart1;
    let isoDateStart1;
    if(DateStart1){
      getdateStart1 = new Date(DateStart1);
      isoDateStart1 = new Date(getdateStart1.getTime() - (getdateStart1.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStop1;
    let isoDateStop1;
    if(DateStop1){
      getdateStop1 = new Date(DateStop1);
      isoDateStop1 = new Date(getdateStop1.getTime() - (getdateStop1.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStart2;
    let isoDateStart2;
    if(DateStart2){
      getdateStart2 = new Date(DateStart2);
      isoDateStart2 = new Date(getdateStart2.getTime() - (getdateStart2.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStop2;
    let isoDateStop2;
    if(DateStop2){
      getdateStop2 = new Date(DateStop2);
      isoDateStop2 = new Date(getdateStop2.getTime() - (getdateStop2.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStart3;
    let isoDateStart3;
    if(DateStart3){
      getdateStart3 = new Date(DateStart3);
      isoDateStart3 = new Date(getdateStart3.getTime() - (getdateStart3.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStop3;
    let isoDateStop3;
    if(DateStop3){
      getdateStop3 = new Date(DateStop3);
      isoDateStop3 = new Date(getdateStop3.getTime() - (getdateStop3.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStart4;
    let isoDateStart4;
    if(DateStart4){
      getdateStart4 = new Date(DateStart4);
      isoDateStart4 = new Date(getdateStart4.getTime() - (getdateStart4.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStop4;
    let isoDateStop4;
    if(DateStop4){
      getdateStop4 = new Date(DateStop4);
      isoDateStop4 = new Date(getdateStop4.getTime() - (getdateStop4.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStart5;
    let isoDateStart5;
    if(DateStart5){
      getdateStart5 = new Date(DateStart5);
      isoDateStart5 = new Date(getdateStart5.getTime() - (getdateStart5.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStop5;
    let isoDateStop5;
    if(DateStop5){
      getdateStop5 = new Date(DateStop5);
      isoDateStop5 = new Date(getdateStop5.getTime() - (getdateStop5.getTimezoneOffset() * 60000)).toISOString();
    }

    let getdateInvoice;
    let isoDateInvoice;
    if(DateInvoice){
      getdateInvoice = new Date(DateInvoice);
      isoDateInvoice = new Date(getdateInvoice.getTime() - (getdateInvoice.getTimezoneOffset() * 60000)).toISOString();
    }

    //console.log(isoDateInvoice);

    const MemberId = document.getElementById("memberInput"+id).value;
    //console.log(MemberId);

    const EmployeeId = document.getElementById("employeeInput"+id).value;
    //console.log(EmployeeId);

    const AboId = document.getElementById("aboInput"+id).value;
    //console.log(AboId);

    let objString = '?id=' + id;

    if(Subject_de){
      objString = objString + '&Subject_de=' + Subject_de;
    } else {
      objString = objString + '&Subject_de=';
    }
    if(Subject_en){
      objString = objString + '&Subject_en=' + Subject_en;
    } else {
      objString = objString + '&Subject_en=';
    }
    if(Subject_fr){
      objString = objString + '&Subject_fr=' + Subject_fr;
    } else {
      objString = objString + '&Subject_fr=';
    }
    if(Subject_es){
      objString = objString + '&Subject_es=' + Subject_es;
    } else {
      objString = objString + '&Subject_es=';
    }
    if(Subject_it){
      objString = objString + '&Subject_it=' + Subject_it;
    } else {
      objString = objString + '&Subject_it=';
    }
    if(Description){
      objString = objString + '&Description=' + Description;
    } else {
      objString = objString + '&Description=';
    }
    if(Price){
      objString = objString + '&Price=' + Price;
    } else {
      objString = objString + '&Price=';
    }
    if(Discount){
      objString = objString + '&Discount=' + Discount;
    } else {
      objString = objString + '&Discount=';
    }
    if(Rates){
      objString = objString + '&Rates=' + Rates;
    } else {
      objString = objString + '&Rates=';
    }
    if(Interval){
      objString = objString + '&Interval=' + Interval;
    } else {
      objString = objString + '&Interval=';
    }
    if(Priceextend){
      objString = objString + '&Priceextend=' + Priceextend;
    } else {
      objString = objString + '&Priceextend=';
    }
    if(MemberId){
      objString = objString + '&CustomerId=' + MemberId;
    } else {
      objString = objString + '&CustomerId=';
    }
    if(EmployeeId){
      objString = objString + '&EmployeeId=' + EmployeeId;
    } else {
      objString = objString + '&EmployeeId=';
    }
    if(AboId){
      objString = objString + '&AboId=' + AboId;
    } else {
      objString = objString + '&AboId=';
    }
    if(DateStart){
      objString = objString + '&DateStart=' + isoDateStart;
    } else {
      objString = objString + '&DateStart=';
    }
    if(DateStop){
      objString = objString + '&DateStop=' + isoDateStop;
    } else {
      objString = objString + '&DateStop=';
    }
    if(DateStart1){
      objString = objString + '&DateStart1=' + isoDateStart1;
    } else {
      objString = objString + '&DateStart1=';
    }
    if(DateStop1){
      objString = objString + '&DateStop1=' + isoDateStop1;
    } else {
      objString = objString + '&DateStop1=';
    }
    if(DateStart2){
      objString = objString + '&DateStart2=' + isoDateStart2;
    } else {
      objString = objString + '&DateStart2=';
    }
    if(DateStop2){
      objString = objString + '&DateStop2=' + isoDateStop2;
    } else {
      objString = objString + '&DateStop2=';
    }
    if(DateStart3){
      objString = objString + '&DateStart3=' + isoDateStart3;
    } else {
      objString = objString + '&DateStart3=';
    }
    if(DateStop3){
      objString = objString + '&DateStop3=' + isoDateStop3;
    } else {
      objString = objString + '&DateStop3=';
    }
    if(DateStart4){
      objString = objString + '&DateStart4=' + isoDateStart4;
    } else {
      objString = objString + '&DateStart4=';
    }
    if(DateStop4){
      objString = objString + '&DateStop4=' + isoDateStop4;
    } else {
      objString = objString + '&DateStop4=';
    }
    if(DateStart5){
      objString = objString + '&DateStart5=' + isoDateStart5;
    } else {
      objString = objString + '&DateStart5=';
    }
    if(DateStop5){
      objString = objString + '&DateStop5=' + isoDateStop5;
    } else {
      objString = objString + '&DateStop5=';
    }

    if(DeskBreak1){
      objString = objString + '&DeskBreak1=' + DeskBreak1;
    } else {
      objString = objString + '&DeskBreak1=';
    }
    if(DeskBreak2){
      objString = objString + '&DeskBreak2=' + DeskBreak2;
    } else {
      objString = objString + '&DeskBreak2=';
    }
    if(DeskBreak3){
      objString = objString + '&DeskBreak3=' + DeskBreak3;
    } else {
      objString = objString + '&DeskBreak3=';
    }
    if(DeskBreak4){
      objString = objString + '&DeskBreak4=' + DeskBreak4;
    } else {
      objString = objString + '&DeskBreak4=';
    }
    if(DeskBreak5){
      objString = objString + '&DeskBreak5=' + DeskBreak5;
    } else {
      objString = objString + '&DeskBreak5=';
    }
    
    if(ExtendDay1){
      objString = objString + '&ExtendDay1=' + ExtendDay1;
    } else {
      objString = objString + '&ExtendDay1=';
    }
    if(ExtendDay2){
      objString = objString + '&ExtendDay2=' + ExtendDay2;
    } else {
      objString = objString + '&ExtendDay2=';
    }
    if(ExtendDay3){
      objString = objString + '&ExtendDay3=' + ExtendDay3;
    } else {
      objString = objString + '&ExtendDay3=';
    }
    if(ExtendDay4){
      objString = objString + '&ExtendDay4=' + ExtendDay4;
    } else {
      objString = objString + '&ExtendDay4=';
    }
    if(ExtendDay5){
      objString = objString + '&ExtendDay5=' + ExtendDay5;
    } else {
      objString = objString + '&ExtendDay5=';
    }
    if(ExtendText1){
      objString = objString + '&ExtendText1=' + ExtendText1;
    } else {
      objString = objString + '&ExtendText1=';
    }
    if(ExtendText2){
      objString = objString + '&ExtendText2=' + ExtendText2;
    } else {
      objString = objString + '&ExtendText2=';
    }
    if(ExtendText3){
      objString = objString + '&ExtendText3=' + ExtendText3;
    } else {
      objString = objString + '&ExtendText3=';
    }
    if(ExtendText4){
      objString = objString + '&ExtendText4=' + ExtendText4;
    } else {
      objString = objString + '&ExtendText4=';
    }
    if(ExtendText5){
      objString = objString + '&ExtendText5=' + ExtendText5;
    } else {
      objString = objString + '&ExtendText5=';
    }

    if(DateInvoice){
      objString = objString + '&DateInvoice=' + isoDateInvoice;
    } else {
      objString = objString + '&DateInvoice=';
    }
    if(LocationId){
      objString = objString + '&LocationId=' + LocationId;
    } else {
      //objString = objString + '&LocationId=';
    }
    if(this.invoiceId){
      objString = objString + '&InvoiceId=' + this.invoiceId;
    } else {
      //objString = objString + '&InvoiceId=';
    }
    if(Abotype){
      objString = objString + '&Abotype=' + Abotype;
    } else {
      objString = objString + '&Abotype=';
    }
    if(Numberentries){
      objString = objString + '&Numberentries=' + Numberentries;
    } else {
      objString = objString + '&Numberentries=';
    }
    if(Remindertype){
      objString = objString + '&Remindertype=' + Remindertype;
    } else {
      objString = objString + '&Remindertype=';
    }

    let rateString = '';
    let valutaString = '';
    let priceString = '';
    for (let i = 0; i < this.arrRates.length; i++) { 
      rateString += '&DateRate'+this.arrRates[i].id+'='+document.getElementById("DateRate"+this.arrRates[i].id+id).getElementsByTagName("input")[0].value;
      valutaString += '&DateValuta'+this.arrRates[i].id+'='+document.getElementById("DateValuta"+this.arrRates[i].id+id).getElementsByTagName("input")[0].value;
      priceString += '&PriceRate'+this.arrRates[i].id+'='+document.getElementById("PriceRate"+this.arrRates[i].id+id).getElementsByTagName("input")[0].value;
    }
    //console.log(rateString);
    //console.log(priceString);
    //console.log(valutaString);

    //Get Duedate Valuta and Price
    objString = objString + rateString + valutaString + priceString;
    
    //console.log(objString);

    fetch(apihost+'/de/vue/contract/getcontract/'+id+'/save'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);

      //update Frontend
      this.dateStart = data.DateStart;
      this.dateStop = data.DateStop;
      this.dateStart1 = data.DateStart1;
      this.dateStop1 = data.DateStop1;
      this.dateStart2 = data.DateStart2;
      this.dateStop2 = data.DateStop2;
      this.dateStart3 = data.DateStart3;
      this.dateStop3 = data.DateStop3;
      this.dateStart4 = data.DateStart4;
      this.dateStop4 = data.DateStop4;
      this.dateStart5 = data.DateStart5;
      this.dateStop5 = data.DateStop5;

      this.dayExt1 = data.ExtendDay1;
      this.dayExt2 = data.ExtendDay2;
      this.dayExt3 = data.ExtendDay3;
      this.dayExt4 = data.ExtendDay4;
      this.dayExt5 = data.ExtendDay5;

      this.textExt1 = data.ExtendText1;
      this.textExt2 = data.ExtendText2;
      this.textExt3 = data.ExtendText3;
      this.textExt4 = data.ExtendText4;
      this.textExt5 = data.ExtendText5;

      this.dateInvoice = data.DateInvoice;
      this.locationId = data.LocationId;

      this.validDates = data.ValidDates;

      this.price = data.Price;
      this.discount = data.Discount;
      this.rates = data.Rates;
      this.interval = data.Interval;

      this.arrRates = data.arrRates;
      this.savedaboId = data.Aboid


      let arrValiddates = [];
      if(this.validDates){
        let datesValid = this.validDates;
        //console.log(validdates);
        for(var i in datesValid){
          //console.log(validdates[i]);
          let date = new Date(datesValid[i]);
          //console.log(date);
          arrValiddates.push(date);
        }

      }
      //console.log(arrValiddates);
      this.disabledDates = arrValiddates;

      //Update List-Title

      //start titleAboSubject
      const titleAboSubject = document.getElementById('titleAboSubject'+id);
      const getAboSubject = document.getElementById('getAboSubject'+id);
      if(titleAboSubject){
        titleAboSubject.innerHTML = getAboSubject.innerHTML;
        //titleAboSubject.innerHTML = "I have changed!";
      }
      //end titleAboSubject

      //start titleValidDates
      const titleValidDates = document.getElementById('titleValidDates'+id);
      if(titleValidDates){
        if(arrValiddates){
          const dateStart = arrValiddates.slice(0, 1);
          const dateStop = arrValiddates.slice(-1);
          const timeStart = new Date(dateStart).getTime();
          const timeStop = new Date(dateStop).getTime();
          const stringStart = new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(timeStart);
          const stringStop = new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(timeStop);
          titleValidDates.innerHTML = stringStart + ' – ' + stringStop;
          //titleValidDates.innerHTML = "I have changed!";
        }
      }
      //end titleValidDates

      //start titlePrice
      const titlePrice = document.getElementById('titlePrice'+id);
      if(titlePrice){
        const val = (Price/1).toFixed(2).replace('.', '.');
        const price = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        titlePrice.innerHTML = this.templateData.data.Currency+ ' ' + price;
        //titlePrice.innerHTML = 'CHF ' + price;
        //titlePrice.innerHTML = "I have changed!";
      }
      //end titlePrice

      //start titleRates
      const titleRates = document.getElementById('titleRates'+id);
      if(titleRates){
        let textRates = '';
        if(Rates > 1){
          textRates = Rates + ' Raten'
        } else {
          textRates =  Rates + ' Rate'
        }
        titleRates.innerHTML = textRates;
        //titleRates.innerHTML = "I have changed!";
      }
      //end titleRates


    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappContractFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

  },    

  },

};

</script>

<style>

</style>
