<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

  <h2>
    <i class="dx-icon nav-icon fa-light fa-tag"></i> 
    {{ title }}
  </h2>


    <div class="dx-card" style="position: relative;">

      <!-- allow-adding="false" then this -->
      <div style="position: absolute; top: 15px; left: 15px; z-index: 1;">
        <DxSelectBox
          :dataSource="groups"
          ref="groupname"
          value-expr="id"
          display-expr="name"
          v-model:value="valueGroup"
          @value-changed="(e) => {
            if(e.value === 0){
              this.$refs.myDataGrid.instance.clearFilter();
            } else {
              this.$refs.myDataGrid.instance.filter(['groupId', '=', e.value]);
            }
            valueGroup = e.value;
          }"
          >
        </DxSelectBox>
      </div>
      <div style="position: absolute; top: 30px; left: 325px; z-index: 1;" v-if="this.valueGroup !== 0">
        <button @click="() => {
            this.editGroupval = '';
            this.$refs.editgroupval.instance.reset();
            editGroup = true;     
          }"
          class="cx-button tiny"><i class="fa-light"></i>
          Gruppe bearbeiten
        </button>
      </div>
      <DxPopup
         title="Gruppe bearbeiten"
         :visible="editGroup"
         width="600"
         height="225"
         :show-close-button="true"
         @hidden="() => {
            editGroup = false;
          }"
      >
      <DxValidationGroup ref="editgroupval">
        <div class="grid-x">
           <div class="cell auto" style="display: flex; align-items: center"> Neue Gruppenname </div>
           <div class="cell shrink">
             <DxTextBox :value="editGroupval" @value-changed="(e) => this.editGroupval = e.value">
               <DxValidator ref="groupbox">
                 <DxRequiredRule message="Gruppenname darf nicht leer sein." />
               </DxValidator>
             </DxTextBox>
           </div>
        </div>
        <div class="grid-x" style="padding-top: 25px;">
           <div class="cell auto"></div>
             
           <div class="cell shrink" style="padding-left: 15px">
             <DxButton 
               text="Gruppe sichern"
               @click="async (e) => {let ret = await handleGroupEdit(e); if(ret) editGroup = false}"
               />
           </div>
        </div>
      </DxValidationGroup>
      </DxPopup>
      <div style="position: absolute; top: 30px; left: 450px; z-index: 1;" v-if="this.valueGroup !== 0">
        <button @click="() => deleteGroupPopup = true"
          class="cx-button tiny"><i class="fa-light"></i>
          Gruppe löschen
        </button>
      </div>
     <DxPopup 
         title="Gruppelöschung"
         :visible="deleteGroupPopup"
         width="300"
         height="200"
         :show-close-button="true"
         @hidden="() => {
            deleteGroupPopup = false;
          }"
      >
      <div>
        Sind Sie sicher dass Sie diese Gruppe löschen wollen? 
      </div> 
      <div class="grid-x" style="padding-top:25px">
        <div class="cell auto">
          <DxButton
          text="Ja"
          @click="async () => { let id =  this.valueGroup; await deleteGroup(id); deleteGroupPopup = false;}"/>
        </div>
        <div class="cell auto">
          <DxButton
            text="Nein"
            @click="() => deleteGroupPopup = false"
          />
        </div>
      </div>
     </DxPopup> 
      <div style="position: absolute; top: 30px; right: 15px; z-index: 1;">
        <button @click="() => {
          this.newGroup.Name = '';
          this.$refs.itemgroupvalgroup.instance.reset();
          this.addNewGroup = true;
        }" 
        class="cx-button tiny"><i class="fa-light fa-tag"></i>
          Gruppe erstellen
        </button>
      </div>
      <div style="position: absolute; top: 30px; right: 135px; z-index: 1;">
        <button @click="() => {
            this.newProduct = {};
            this.newProduct.Subject = '';
            this.newProduct.Price = '';
            this.$refs.valgroup.instance.reset();
            addNewItem = true;
          }" 
          class="cx-button tiny"><i class="fa-light fa-tag"></i> 
            Artikel erstellen
        </button>
      </div>
        <DxPopup
         title="Artikel erstellen"
         :visible="addNewItem"
         width="600"
         height="600"
         :show-close-button="true"
         @hidden="() => {
            addNewItem = false;
          }"
         >
          <DxValidationGroup ref="valgroup">
            <div class="grid-x">
              <div class="cell auto" style="display: flex; align-items: center"> Produktname </div>
              <div class="cell shrink">
                <DxTextBox :value="newProduct.Subject" @value-changed="(e) => newProduct.Subject = e.value">
                  <DxValidator ref="prodbox">
                    <DxRequiredRule message="Produktname darf nicht leer sein." />
                  </DxValidator>
                </DxTextBox>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px">
              <div class="cell auto" style="display: flex; align-items: center"> Beschreibung </div>
              <div class="cell shrink">
                <DxTextArea :height="225" :value="newProduct.Description" @value-changed="(e) => newProduct.Description = e.value"/>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto" style="display: flex; align-items: center"> Artikelnummer </div>
              <div class="cell shrink">
                <DxTextBox :value="newProduct.Sku" @value-changed="(e) => newProduct.Sku = e.value"/>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto" style="display: flex; align-items: center"> Preis </div>
              <div class="cell shrink">
                <DxTextBox :value="newProduct.Price" @value-changed="(e) => newProduct.Price = e.value">
                  <DxValidator ref="pricebox">
                    <DxRequiredRule message="Produktpreis darf nicht leer sein." />
                    <DxPatternRule pattern="^\d+\.?\d{0,2}$" message="Ungültier Preis" /> <!-- Allow up to two decimal places -->
                  </DxValidator>
                </DxTextBox>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto" style="display: flex; align-items: center"> EAN </div>
              <div class="cell shrink">
                <DxTextBox :value="newProduct.Barcode" @value-changed="(e) => newProduct.Barcode = e.value"/>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto" style="display: flex; align-items: center"> MwSt Satz  </div>
              <div class="cell shrink">
                <DxSelectBox
                  :data-source="vatList"
                  :value="newProduct.Vat"
                  display-expr="display"
                  value-expr="id"
                  @value-changed="(e) => newProduct.Vat = e.value"/>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto" style="display: flex; align-items: center"> Gruppe  </div>
              <div class="cell shrink">
                <DxSelectBox
                  :data-source="groups"
                  display-expr="name"
                  value-expr="id"
                  @value-changed="(e) => {newProduct.groupId = e.value}">
                  <DxValidator ref="groupbox">
                    <DxRequiredRule message="Gruppenname darf nicht leer sein." />
                  </DxValidator> 
                </DxSelectBox>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto" style="display: flex; align-items: center"> Frontdesk </div>
              <div class="cell shrink">
                <DxCheckBox :value="newProduct.Isfrontdesk ?? false" @value-changed="(e) => newProduct.Isfrontdesk = e.value"/>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto" style="display: flex; align-items: center"> Webshop </div>
              <div class="cell shrink">
                <DxCheckBox :value="newProduct.Iswebshop ?? false" @value-changed="(e) => newProduct.Iswebshop = e.value"/>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto"></div>
              <div class="cell shrink">
                <DxButton 
                  text="Artikel erstellen"
                  @click="async (e) => {let ret = await handleSubmit(e); if(ret) { addNewItem = false }}"
                  />
              </div>
            </div>
          </DxValidationGroup>
        </DxPopup>
        
        <DxPopup
         title="Gruppe erstellen"
         :visible="addNewGroup"
         width="600"
         height="225"
         :show-close-button="true"
         @hidden="() => {
            addNewGroup = false;
          }"
         >
          <DxValidationGroup ref="itemgroupvalgroup">
            <div class="grid-x">
              <div class="cell auto" style="display: flex; align-items: center"> Gruppenname </div>
              <div class="cell shrink">
                <DxTextBox :value="newGroup.Name" @value-changed="(e) => newGroup.Name = e.value">
                  <DxValidator ref="namebox">
                    <DxRequiredRule message="Gruppenname darf nicht leer sein." />
                  </DxValidator>
                </DxTextBox>
              </div>
            </div>
            <div class="grid-x" style="padding-top: 25px;">
              <div class="cell auto"></div>
              <div class="cell shrink">
                <DxButton 
                  text="Gruppe erstellen"
                  @click="async (e) => {let ret = await handleGroupSubmit(e); if(ret) addNewGroup = false}"
                />
              </div>
            </div>
          </DxValidationGroup>
          
        </DxPopup>

          <DxDataGrid
            ref="myDataGrid"
            id="gridContainer"
            :data-source="customDataSource"
            
            :focused-row-enabled="false"
            :column-auto-width="true"
            :column-hiding-enabled="true"

            :allow-column-reordering="true"
            :show-borders="false"
  
            @editing-start="logEvent('EditingStart')"
            @init-new-row="logEvent('InitNewRow')"
            @row-inserting="logEvent('RowInserting')"
            @row-inserted="logEvent('RowInserted')"
            @row-updating="logEvent('RowUpdating')"
            @row-updated="logEvent('RowUpdated')"
            @row-removing="logEvent('RowRemoving')"
            @row-removed="logEvent('RowRemoved')"
            @saving="logEvent('Saving')"
            @saved="logEvent('Saved')"
            @edit-canceling="logEvent('EditCanceling')"
            @edit-canceled="logEvent('EditCanceled')"
            style="padding-top: 75px;"
          >
  
          <DxPaging :page-size="500" />
          <DxPager :show-page-size-selector="false" :show-info="true" />
          <DxFilterRow :visible="true" />
  
          <DxEditing
            :allow-updating="false"
            :allow-deleting="true"
            :allow-adding="false"
            mode="row"
          />

          <DxColumn
            alignment='left' 
            data-field="Subject"
            :caption="currentUser.Translation.vueappTaskSubject"
            :placeholder="currentUser.Translation.vueappTaskSubject"
            cell-template="subjectTemplate"
            :hiding-priority="99"
          />
          <template #subjectTemplate="{ data: content }">

            <div class="grid-x align-middle">

              <div class="shrink cell">

                <!-- image-thumb -->
                <div class="image-thumb" style="margin: 2px 10px 2px 0;"
                  v-bind:style="{ 'background-image': 'url(' + content.data.Picthumb + '?v=' + timestamp + ')' }" >
                </div>
                <!-- /image-thumb -->

              </div><!-- /shrink cell main -->

              <div class="auto cell">
                <div class="grid-x align-middle">

                  <div class="shrink cell">
                    <!-- row 1 -->
                    <div class="fixedwidth-listitem">
                      <p class="ck-overflow-ellipsis strong small">
                        <span v-if="content.data.Subject">
                          {{ content.data.Subject }}
                        </span>
                        <span v-else class="alert-color">
                          <i class="fa-light fa-triangle-exclamation"></i> Bezeichnung
                        </span>
                      </p>
                    </div><!-- /fixedwidth-listitem -->
                  </div><!-- /shrink cell -->

                  <div class="shrink cell">
                    <!-- row 2 -->
                    <div class="fixedwidth-listitem">
                      <p class="ck-overflow-ellipsis -strong small">
                        <span v-if="content.data.Sku">
                          Art-Nr. {{ content.data.Sku }}
                        </span>
                        <span v-else class="alert-color">
                          <i class="fa-light fa-triangle-exclamation"></i> Art-Nr
                        </span>
                      </p>
                    </div><!-- /fixedwidth-listitem -->
                  </div><!-- /shrink cell -->

                  <div class="shrink cell">
                    <!-- row 3 -->
                    <div class="fixedwidth-listitem">
                      <p class="ck-overflow-ellipsis -strong small">
                        <span v-if="content.data.Price">
                          CHF {{ formatPrice(content.data.Price) }}
                        </span>
                        <span v-else class="alert-color">
                          <i class="fa-light fa-triangle-exclamation"></i> Preis
                        </span>
                      </p>
                    </div><!-- /fixedwidth-listitem -->
                  </div><!-- /shrink cell -->

                  <div class="shrink cell -hide">
                    <!-- row 4 -->
                    <div class="fixedwidth-listitem">
                      <p class="ck-overflow-ellipsis -strong small">
                        <span v-if="content.data.Barcode">
                          EAN: {{ content.data.Barcode }}
                        </span>
                        <span v-else class="alert-color">
                          <i class="fa-light fa-triangle-exclamation"></i> EAN
                        </span>
                      </p>
                    </div><!-- /fixedwidth-listitem -->
                  </div><!-- /shrink cell -->


                  <div class="shrink cell -hide">
                    <!-- row 5 -->
                    <div class="fixedwidth-listitem">
                      <p class="ck-overflow-ellipsis -strong small">
                        <span class="success-color" v-if="content.data.isFrontdesk">
                          <i class="fa-light fa-circle-check"></i> Frontdesk
                        </span>
                        <span v-else class="-alert-color" style="color: #999999;">
                          <i class="fa-light fa-circle-xmark"></i> Frontdesk
                        </span>
                      </p>
                      <p class="ck-overflow-ellipsis -strong small">
                        <span class="success-color" v-if="content.data.isWebshop">
                          <i class="fa-light fa-circle-check"></i> Webshop
                        </span>
                        <span v-else class="-alert-color" style="color: #999999;">
                          <i class="fa-light fa-circle-xmark"></i> Webshop
                        </span>
                      </p>

                    </div><!-- /fixedwidth-listitem -->
                  </div><!-- /shrink cell -->

                  <div class="shrink cell">
                    <div class="fixedwidth-listitem">
                      <p class="ck-overflow-ellipsis small">
                        <span>
                          Gruppe: {{ groups.find((el) => el.id === content.data.groupId) !== undefined ? groups.find((el) => el.id === content.data.groupId).name : 'Alle' }}
                        </span>
                      </p>
                    </div><!-- /fixedwidth-listitem -->
                  </div>
                </div><!-- /grid-x sub-->
              </div><!-- /auto cell main -->
            </div><!-- /grid-x main -->

          </template>

          <DxMasterDetail
            :enabled="true"
            template="masterDetailTemplate"
          />
          <template #masterDetailTemplate="{ data: content }">
            <DetailTemplate
              :template-data="content"
              :groups="groups"
              @group-change="(e) => {customDataSource.update(e.id, e).done(() => {this.$refs.myDataGrid.instance.refresh(); this.$refs.myDataGrid.instance.collapseRow(e.id)})}"
            />
          </template>
  
        </DxDataGrid>

    </div><!-- dx-card -->
    
    </div><!-- content-block -->
  
    <div v-else class="content-block">
      Oh no 😢
    </div><!-- content-block -->
  
    <div class="content-block hide">
      <div id="events">
  
        <div class="grid-x grid-margin-x"> 
          <div class="auto cell"> 
            <div class="caption">
              Fired events:
            </div>
            <ul class="menu vertical">
            <li
              v-for="(event, index) in events"
              :key="index"
            >{{ event }}</li>
            </ul>
          </div>
          <div class="shrink cell"> 
            <DxButton
              id="clear"
              text="Clear"
              @click="clearEvents()"
            />
          </div>
        </div><!-- grid-x -->
  
      </div><!-- events -->
    </div><!-- content-block -->
  
  </template>
  
  <script>
  
  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxMasterDetail,
    DxRequiredRule,
    DxPatternRule,
    //DxSelection,
    // DxScrolling,

    //DxLookup, 
  } from 'devextreme-vue/data-grid';
 
  import CustomStore from 'devextreme/data/custom_store';
  import DetailTemplate from '../parts/shop-item-detail-master.vue';
  import { ref } from 'vue'
  import ArrayStore from 'devextreme/data/array_store';
  //import Vue3Barcode from 'vue3-barcode'

  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  import { DxPopup, DxTextBox, DxValidator, DxSelectBox, DxTextArea, DxCheckBox, DxButton, DxValidationGroup } from 'devextreme-vue';
  import notify from 'devextreme/ui/notify';
  //console.log(apihost);
    
  const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3
  }

  let currentUser;
  let vatList = ref(undefined);
  let groups = ref([{'name' : 'Alle', 'id' : 0}]);
  let rerender = ref(false);
  let valueGroup = ref(0);
  let editGroupval = ref('');
  
  auth.getUser().then((e) => {
    if(e.data){
      currentUser = e.data;
      // console.log(currentUser.id);
      // console.log(currentUser.Constant.languages);
      // console.log(currentUser.Email);
      // console.log(currentUser.Language);
    } // e.data
  }); //auth
  
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  const resp = await fetch(apihost + '/vue/shop/group/list/');
  const data = await resp.json();
  data.forEach((val) => groups.value.push(val)); 

  const response = await fetch(apihost + "/vue/vat/list/");
  const vatListObject = await response.json();
  vatListObject.forEach((vat) => vat.display = `${vat.name} - ${vat.valueTax}% - ${vat.nameDetail}`);
  vatList.value = new ArrayStore({
      data: vatListObject,
      key: 'id',
  });

  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      return fetch(apihost+'/'+currentUser.Language+'/vue/shop/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/shop/getshop/'+key+'/insert'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/shop/getshop/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/shop/getshop/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }
  
  });

  export default {
  
    mounted() {

      //redirect to Member App
      if(currentUser.Role == 'Customer'){
        const currentUrl = window.location.href;
        const redirecturl = currentUrl.replace("/#/sap-settingshopitem", "/#/dashboard");
        window.location.href = redirecturl;
      }

      this.timestamp = this.printTimestamp();
  
    },
  
    components: {
      DxDataGrid,
      DxMasterDetail,
      DxFilterRow,
      DxColumn,
      DxEditing,
      DxPager,
      DxPaging,
      DxButton,
      DxPopup,
      DxTextBox,
      DxRequiredRule,
      DxValidator,
      DxPatternRule,
      DxSelectBox,
      DxTextArea,
      DxCheckBox,
      DxValidationGroup,
      DetailTemplate,
      // DxForm,
      // DxGroupItem,
      // DxSimpleItem,
      // DxButtonItem,
      // DxLabel,
      // DxFileUploader,
      // DxDropDownBox,
      // DxSelection,
      // DxScrolling,
      //DxTextBox,
      //DxRequiredRule,
      //DxCompareRule,
      //DxPatternRule,
      //DxRangeRule,
      //DxEmailRule,
      //DxStringLengthRule,
      //DxAutocomplete,
      //DxAsyncRule,
      //DxLookup,
      //Vue3Barcode,

    },
    data() {
      return {
        apihost,
        
        title: currentUser.Translation.vueappNavMemberShopitem,
        events: [],
        formInstance: null,
        // statuses,
        // priorities,
        // shoptypes,
        // shopcategory,
        currentUser,
        customDataSource,
        colCountByScreen,
        vatList,
        // //Option 1
        // option1DataSource,
        // option1Columns,
        // option1BoxValue,
        // //changeOption1,
        // //readyOption1,

        // //Option 2
        // option2DataSource,
        // option2Columns,
        // option2BoxValue,
        // //changeOption2,
        // //readyOption2,
  
        calendarOptions: { 
          displayFormat: "dd.MM.yyyy"
        },
        buttonOptions: {
          text: currentUser.Translation.vueappShopFormSubmit,
          type: 'success',
          useSubmitBehavior: true,
        },
        dateBoxOptions: {
          invalidDateExerciseoption2:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidExerciseoption2: 'The phone must have a correct USA phone format',
        },
  
        statusPattern: /^[^0-9]+$/,
        priorityPattern: /^[^0-9]+$/,
        shopPattern: /^[^0-9]+$/,
  
        // phonePattern: /^[02-9]\d{9}$/,
        // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
        
        validationRules: {
  
          // status: [
          //   { type: 'required', shop: currentUser.Translation.vueappTaskStatusExerciseoption2 },
          // ],
          // priority: [
          //   { type: 'required', shop: currentUser.Translation.vueappTaskPriorityExerciseoption2 },
          // ],
        },
        //statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        //priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
        // statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        // priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },

        addNewItem : false,
        editGroup : false,
        deleteGroupPopup : false,
        addNewGroup : false,
        newProduct : {},
        newGroup : {},
        showGroups : false,
        rerender,
        groups,
        valueGroup,
        editGroupval,
      };
    },
    methods: {


      formatDate(dateString) {
        //https://jerickson.net/how-to-format-dates-in-vue-3/
        const date = new Date(dateString);
        // Then specify how you want your dates to be formatted
        return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      },

      formatPrice(value) {
        //https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
        let val = (value/1).toFixed(2).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
      },

      validateForm(e) {
        e.component.validate();
      },
      
      logEvent(eventName) {
        //console.log(this.events);
        this.events.unshift(eventName);
      },
      clearEvents() {
        this.events = [];
      },
  
      printTimestamp: function () {
        return Date.now();
      },
    
      async handleSubmit(e) {
        let result = e.validationGroup.validate();
        if(result.isValid){
          this.newProduct.Description ??= "";  
          this.newProduct.Isfrontdesk ??= false;
          this.newProduct.Iswebshop ??= false;
          await customDataSource.insert(this.newProduct).catch((e) => console.log(e));
          this.addNewItem = false;
          return 1;
        } else {
          notify("Name/Preis/Gruppe ungültig", 'error',{ position: "bottom", direction: "up-stack"});
          return 0;
        }
      },

      async handleGroupSubmit(e) {
        let result = e.validationGroup.validate();
        if(result.isValid){
          let name = encodeURIComponent(this.newGroup.Name);
          let resp = await fetch(apihost + '/vue/shoppingcart/getgroup/0/' + name + '/insert/');
          let data = await resp.json();
          groups.value.push({'id': data.id, 'name': data.name});
          this.$refs.groupname.instance.getDataSource().reload();
          this.addNewGroup = false;
          notify('Gruppe erfolgreich erstellt', 'success', 3000);
          return 1;
        } else {
          notify("Gruppenname leer!", 'error', 3000);
          return 0;
        }
      },

      async handleGroupEdit(e) {
        let result = e.validationGroup.validate();
        if(result.isValid){
          let name = encodeURIComponent(this.editGroupval);
          let resp = await fetch(apihost + '/vue/shoppingcart/getgroup/' + this.valueGroup + '/' + name + '/save/');
          let data = await resp.json();
          const indx = groups.value.map((e) => e.id).indexOf(this.valueGroup);
          groups.value[indx].name = this.editGroupval;
          this.valueGroup = 0;
          this.$refs.groupname.instance.getDataSource().reload();
          // console.log(resp); 
          this.editGroup = false;
          notify('Gruppe erfolgreich bearbeitet', 'success', 3000);
          return 1;
        } else {
          notify("Gruppenname leer!", 'error', 3000);
          return 0;
        }
      },

      async deleteGroup(id){
        let resp = await fetch(apihost + '/vue/shoppingcart/getgroup/' + id + '/remove/remove/');
        let data = await resp.json();
        groups.value = groups.value.filter((el) => el.id != id);
        this.valueGroup = 0; 
        this.$refs.groupname.instance.getDataSource().reload();
        notify("Gruppe erfolgreich gelöscht", 'success', 3000);
      }
      
    },
  };
  
  
</script>

<style>

</style>
    