<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

  <h2>
    <i class="dx-icon nav-icon fa-light fa-ticket"></i> 
    {{ title }}
  </h2>


    <div class="dx-card -no-paddings" style="position: relative;">

      <!-- allow-adding="false" then this -->
      <div class="" style="position: absolute; top: 15px; right: 15px; z-index: 1;">
        <button @click="createAbo" class="cx-button tiny"><i class="fa-light fa-ticket"></i> Abo erstellen</button>
      </div>

        <DxDataGrid
          ref="myDataGrid"
          id="gridContainer"
          :data-source="customDataSource"
  
          :focused-row-enabled="false"
          :column-auto-width="true"
          :column-hiding-enabled="true"
          
          :allow-column-reordering="true"
          :show-borders="false"
  
          @editing-start="logEvent('EditingStart')"
          @init-new-row="logEvent('InitNewRow')"
          @row-inserting="logEvent('RowInserting')"
          @row-inserted="logEvent('RowInserted')"
          @row-updating="logEvent('RowUpdating')"
          @row-updated="logEvent('RowUpdated')"
          @row-removing="logEvent('RowRemoving')"
          @row-removed="logEvent('RowRemoved')"
          @saving="logEvent('Saving')"
          @saved="logEvent('Saved')"
          @edit-canceling="logEvent('EditCanceling')"
          @edit-canceled="logEvent('EditCanceled')"
        >
  
        <DxPaging :page-size="500" />
        <DxPager :show-page-size-selector="false" :show-info="true" />
        <DxFilterRow :visible="true" />
  
        <DxEditing
          :allow-updating="false"
          :allow-deleting="true"
          :allow-adding="true"
          mode="row"
        />


        <!-- <template #picTemplate="{ data: content }">
          av
          <div class="grid-x align-middle ck-itemrow">
            <div class="cell">
              <div class="image-thumb" v-if="content.data.Picthumb"
                v-bind:style="{ 'background-image': 'url(' + content.data.Picthumb + '?v=' + timestamp + ')' }" >
              </div>
            </div>
          </div>
        </template> -->


        <DxColumn
          alignment='left' 
          data-field="Subject"
          :caption="currentUser.Translation.vueappTaskSubject"
          :placeholder="currentUser.Translation.vueappTaskSubject"
          cell-template="subjectTemplate"
          :hiding-priority="99"
        />
        <template #subjectTemplate="{ data: content }">

          <div class="grid-x align-middle">

            <div class="shrink cell">

              <!-- image-thumb -->
              <div class="image-thumb" style="margin: 0 10px 0 0;"
                v-bind:style="{ 'background-image': 'url(' + content.data.Picthumb + '?v=' + timestamp + ')' }" >
              </div>
              <!-- /image-thumb -->

            </div><!-- /shrink cell main -->

            <div class="auto cell">
              <div class="grid-x align-middle">

                <div class="shrink cell">
                  <!-- row 1 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small">
                      <span v-if="content.data.Subject">
                        {{ content.data.Subject }}

                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Bezeichnung
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis -strong small">
                      id: {{ content.data.id }}
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 2 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis -strong small">
                      <span v-if="content.data.Daysvalid">
                        {{ content.data.Daysvalid }} Tage
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Tage
                      </span>
                    </p>
                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 3 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis -strong small">
                      <span v-if="content.data.Price">
                        {{ content.data.Currency }} {{ formatPrice(content.data.Price) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Preis
                      </span>
                    </p>
                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell -hide">
                  <!-- row 4 -->
                  <div class="fixedwidth-listitem">
                    
                    <p class="ck-overflow-ellipsis -strong small hide">
                      <span v-if="content.data.Code">
                        Code: {{ content.data.Code }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Code
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis -strong small">
                      <span v-if="content.data.Abotype">
                        
                        <span v-if="content.data.Abotype == 'time'">
                          <i class="fa-light fa-calendar-days"></i> Zeitraum
                        </span>
                        <span v-if="content.data.Abotype == 'coupon'">
                          <i class="fa-light fa-ticket-simple"></i> {{ content.data.Numberentries }} Eintritte
                        </span>
                        
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Abotyp
                      </span>
                    </p>


                    <p class="ck-overflow-ellipsis -strong small">
                      <span v-if="content.data.Remindertype">

                        <span v-if="content.data.Remindertype == 'notextend'">
                          <i class="fa-light fa-envelope"></i> Kunde anfragen
                        </span>
                        <span v-if="content.data.Remindertype == 'autoextend'">
                          <i class="fa-light fa-stopwatch-20"></i> automatisch
                        </span>
                        <span v-if="content.data.Remindertype == 'sendmail'">
                          <i class="fa-light fa-hourglass-start"></i> nicht automatisch
                        </span>

                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Verlängerung
                      </span>
                    </p>

                      

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

              </div><!-- /grid-x sub-->

            </div><!-- /auto cell main -->
          </div><!-- /grid-x main -->


        </template>

        <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
        />
        <template #masterDetailTemplate="{ data: content }">
          <DetailTemplate
            :template-data="content"
          />
        </template>
  
      </DxDataGrid>
  
    </div><!-- dx-card -->
    
    </div><!-- content-block -->
  
    <div v-else class="content-block">
      Oh no 😢
    </div><!-- content-block -->
  
    <div class="content-block hide">
      <div id="events">
  
        <div class="grid-x grid-margin-x"> 
          <div class="auto cell"> 
            <div class="caption">
              Fired events:
            </div>
            <ul class="menu vertical">
            <li
              v-for="(event, index) in events"
              :key="index"
            >{{ event }}</li>
            </ul>
          </div>
          <div class="shrink cell"> 
            <DxButton
              id="clear"
              text="Clear"
              @click="clearEvents()"
            />
          </div>
        </div><!-- grid-x -->
  
      </div><!-- events -->
    </div><!-- content-block -->
  
  </template>
  
  <script>
  
  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxButton,
    DxMasterDetail,
    //DxSelection,
    // DxScrolling,

    //DxLookup, 
  } from 'devextreme-vue/data-grid';
  
  import CustomStore from 'devextreme/data/custom_store';
  import DetailTemplate from '../parts/abo-item-detail-master.vue';

  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);
    
  const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3
  }

  let currentUser;
  
  auth.getUser().then((e) => {
    if(e.data){
      currentUser = e.data;
      // console.log(currentUser.id);
      // console.log(currentUser.Constant.languages);
      // console.log(currentUser.Email);
      // console.log(currentUser.Language);
    } // e.data
  }); //auth
  
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      return fetch(apihost+'/'+currentUser.Language+'/vue/abo/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/abo/getabo/'+key+'/insert'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/abo/getabo/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/abo/getabo/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }
  
  });
  

  export default {
  
    mounted() {
        
      //redirect to Member App
      if(currentUser.Role == 'Customer'){
        const currentUrl = window.location.href;
        const redirecturl = currentUrl.replace("/#/sap-settingaboitem", "/#/dashboard");
        window.location.href = redirecturl;
      }

      this.timestamp = this.printTimestamp();
  
    },
  
    components: {
      DxDataGrid,
      DxMasterDetail,
      DxFilterRow,
      DxColumn,
      DxEditing,
      DxPager,
      DxPaging,
      DxButton,
      DetailTemplate,
      // DxForm,
      // DxGroupItem,
      // DxSimpleItem,
      // DxButtonItem,
      // DxLabel,
      // DxFileUploader,
      // DxDropDownBox,
      // DxSelection,
      // DxScrolling,
      //DxTextBox,
      //DxRequiredRule,
      //DxCompareRule,
      //DxPatternRule,
      //DxRangeRule,
      //DxEmailRule,
      //DxStringLengthRule,
      //DxAutocomplete,
      //DxAsyncRule,
      //DxLookup,


    },
    data() {
      return {
        apihost,
        
        title: currentUser.Translation.vueappNavMemberAboitem,
        events: [],
        formInstance: null,
        // statuses,
        // priorities,
        // abotypes,
        // abocategory,
        currentUser,
        customDataSource,
        colCountByScreen,

        // //Option 1
        // option1DataSource,
        // option1Columns,
        // option1BoxValue,
        // //changeOption1,
        // //readyOption1,

        // //Option 2
        // option2DataSource,
        // option2Columns,
        // option2BoxValue,
        // //changeOption2,
        // //readyOption2,
  
        calendarOptions: { 
          displayFormat: "dd.MM.yyyy"
        },
        buttonOptions: {
          text: currentUser.Translation.vueappAboFormSubmit,
          type: 'success',
          useSubmitBehavior: true,
        },
        dateBoxOptions: {
          invalidDateExerciseoption2:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidExerciseoption2: 'The phone must have a correct USA phone format',
        },
  
        statusPattern: /^[^0-9]+$/,
        priorityPattern: /^[^0-9]+$/,
        aboPattern: /^[^0-9]+$/,
  
        // phonePattern: /^[02-9]\d{9}$/,
        // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
        
        validationRules: {
  
          // status: [
          //   { type: 'required', abo: currentUser.Translation.vueappTaskStatusExerciseoption2 },
          // ],
          // priority: [
          //   { type: 'required', abo: currentUser.Translation.vueappTaskPriorityExerciseoption2 },
          // ],
        },
        //statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        //priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
        // statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        // priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
      };
    },
    methods: {

      validateForm(e) {
        e.component.validate();
      },
      
      formatPrice(value) {
        //https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
        let val = (value/1).toFixed(2).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
      },
      
      logEvent(eventName) {
        //console.log(this.events);
        this.events.unshift(eventName);
      },
      clearEvents() {
        this.events = [];
      },
  
      printTimestamp: function () {
        return Date.now();
      },

      createAbo() {
        this.$refs['myDataGrid'].instance.addRow();
      },

    },
  };
  
  
</script>

<style>

</style>
    